import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import LogoFin from '../../images/logo_fin.png';

const GlaucomaSectionSix = ({ takeTest }) => {
  return (
    <Fragment>
      <div className="glaucomaSection">
        <div className="glaucoma-grid">
          <aside className="glaucoma-grid-left">
            <img src={LogoFin} alt="logo-fin" />
          </aside>
          <aside className="glaucoma-grid-right">
            <h1> ¡Haz concluido el test! </h1>
            {/* si alguna respuesta es si */}
            {takeTest === true ? (
              <Fragment>
                <p>
                  Si respondiste <strong> SÍ
                        </strong> a cualquiera de las preguntas anteriores, te recomendamos asistir a una consulta oftalmológica para hacer una valoración dirigida para diagnóstico de glaucoma.
                   </p>
                <p>
                  <span className="boldText"> ¡Agenda una cita ahora mismo! </span>
                  <button className="makeDateBtn"> Hacer una cita </button>
                </p>
              </Fragment>
            ) : (
              <Fragment>
                    <p>
                De acuerdo a lo que respondiste, no hay un indicio de que puedas padecer glaucoma, sin embargo, es importante que realices un chequeo general de tus ojos con un oftalmólogo una vez al año. 
                    </p> 
                    <p>
                     <span className="boldText"> ¿Quieres hacer una cita? </span>
                     <button className="makeDateBtn"> Hacer una cita </button>
                    </p>
              </Fragment>
            )}
            {/* si ninguna respuesta es si */}

            {/* Fin */}
            <div className="finishButtons">
              <button className="finishButtonBlue">
                Enviar resultado por correo
                     </button>
              <button className="finishButtonGray">
                Terminar
                     </button>
            </div>
          </aside>
        </div>
      </div>
    </Fragment>
  );
};

GlaucomaSectionSix.propTypes = {};
export default GlaucomaSectionSix;