import React, { Fragmet, useState, useEffect, useContext } from 'react';
import { Modal, Button, Input, } from 'antd';
import { Auth } from "../context/AuthContext";
import 'antd/dist/antd.css';
import AdminSidebar from '../components/AdminSidebar';
import UserInfo from '../components/UserInfo';
import VisualInfo from '../components/VisualInfo';
import Banner from '../components/Banner';
import firebase from "firebase/app";
import app from "../firebaseConfig";
import TreatmentTable from '../components/TreatmentTable';
import DatesCalendar from '../components/DatesCalendar';

const Profile = ({ userInfo }) => {
  const [loggedProvider, setLoggedProvider] = useState(false);
  const [expedientNumber, setExpedientNumber] = useState(null);
  const [userExpedientInfo, setUserExpedientInfo] = useState({});
  const [visibleNumberWindow, setVisibleNumberWindow] = useState(false);
  const [visibleTmWindow, setVisibleTmWindow] = useState(false);
  const [profileEmail, setProfileEmail] = useState(null);
  const [profilePhone, setProfilePhone] = useState(null);
  const [error, seterror] = useState("");
  

  const tabledata = [
    {
      folio: 'PT-502210',
      tratamiento: "Cx Catarata",
      ojo: 'Izquierdo',
      estado: "Realizado",
      medico: 'José Antonio Soto Muñoz',
      fecha: "02/05/21",
    },
    {
      folio: 'PT-502210',
      tratamiento: "Cx Catarata",
      ojo: 'Derecho',
      estado: "Finalizado",
      medico: 'José Antonio Soto Muñoz',
      fecha: "02/05/21",
    },
    {
      folio: 'PT-502210',
      tratamiento: "Cx Catarata",
      ojo: 'Izquierdo',
      estado: "Finalizado",
      medico: 'José Antonio Soto Muñoz',
      fecha: "02/05/21",
    },
    {
      folio: 'PT-502210',
      tratamiento: "Cx Catarata",
      ojo: 'Derecho',
      estado: "Finalizado",
      medico: 'José Antonio Soto Muñoz',
      fecha: "02/05/21",
    },
  ];

  const columns = [
    { title: "Folio", field: "folio", sorter: "string" },
    { title: "Tratamiento", field: "tratamiento", sorter: "string", width: 250 },
    { title: "Ojo", field: "ojo", sorter: "string" },
    { title: "Estado", field: "estado", },
    { title: "Medico", field: "medico", },
    { title: "Fecha", field: "fecha", },
  ];

  const { usuario } = useContext(Auth);

  const axios = require('axios');

  
  useEffect(() => {
    setProfileEmail(usuario && usuario.email && usuario !== null && usuario.email !== null ? usuario.email : null);
    setProfilePhone(usuario && usuario.phone && usuario !== null && usuario.phone !== null ? usuario.phone : null);
  }, [usuario]);
  
  /************************************************** 
    Funciones para leads 
  ***************************************************/
   
  /* funcion para crear nuevo lead, pendiente pedir el servicio nuevo */

  
  
  /* Buscamos lead por email */
  const foundLeadByMail = async () => {
    const resp = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?action=lead_by_email&correo=${profileEmail}`);
    if (resp.data === undefined || resp.data === null 
      || resp.data.pacientes === null || resp.data.pacientes === undefined) {
      foundLeadByTelephone(); /* ejecuta funcion para buscar lead por telefono */
    } else {
      setVisibleNumberWindow(false);
    }
  }

  const foundLeadByTelephone = async () => {
    const resp = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?action=lead_by_celular&celular=${profilePhone}`);
    const result = resp !== null && resp !== undefined && resp.data !== undefined && resp.data !== null ? resp.data : null ;
    if (result.pacientes === undefined || result.pacientes === null) {
      console.log('crear lead aqui');
      handleCallCreateLead(); /* crear lead */
    } else {
      setVisibleNumberWindow(false);
    }
  }


  const handleCallCreateLead = async () => {
    try {
      const resp = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?id_Firebase=${usuario.uid}&action=login_crear_lead&nombre=${usuario.displayName}&telefono=${profilePhone}&apellidos=""&correo=${profileEmail}`);
    }
    catch (error) {
      console.error('error al llamar el api',error);
    }
  }
   
  /************************************************* 
    Funciones para pacientes 
  *************************************************/

  /* Buscar numero de Expediente del paciente */
  /* const foundExpedientNumber = async () => {
    try {
      const resp = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?action=search_expediente&expediente=${expedientNumber}`);
      if (resp === null || resp.data === null || resp.data === undefined ||  resp.data.pacientes === undefined || resp.data.pacientes === null ) {
        foundAccountByTelephone();
      } else {
        setUserInfo(resp.data.pacientes);
      }
    } catch (error) {
      setVisibleNumberWindow(false);
      setVisibleTmWindow(true);
    }
  } */

  /* Busca cuenta de paciente por telefono */
  /* const foundAccountByTelephone = async () => {
    try {
      const resp = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?action=account_by_celular&celular=${profilePhone}`);
      if (resp === null || resp.data === undefined || resp.data === null || resp.data.pacientes === undefined 
      || resp.data.pacientes === null ) {
        foundAccountByEmail(); /
      } else {
        setUserInfo(resp.data.pacientes);
      }
    } catch (error) {
      foundAccountByEmail();
    }
  }; */

  /*Buscar cuenta de paciente por correo electronico */
  /* const foundAccountByEmail = async () => {
    try {
      const resp = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?action=account_by_email&correo=${profileEmail}`);
      if (resp.data === null || resp.data.length === 0 || resp.data.pacientes === undefined || resp.data.pacientes === null) {
        setVisibleNumberWindow(false); 
        setVisibleTmWindow(true);  
      } else {
        setUserInfo(resp.data.pacientes);
      }
    } catch (error) {
      console.error('error', error);
    }
  }; */
  
  

  /* const saveRegisterIds = (newUid, info_usuario = []) => {
    localStorage.setItem('id', info_usuario[0]['id']);
    localStorage.setItem('idFirebase', newUid);
    localStorage.setItem('oldIdFirebase', info_usuario[0]['idFirebase']);
  } */
  
  return (
    <div className="admin-grid">
      <AdminSidebar 
        displayName={usuario && usuario.displayName && usuario !== null && usuario.displayName !== null ? usuario.displayName : null}
      />
      <div className="profile-holder">
        <section className="profile-aux">
          <UserInfo
            infoPaciente={userInfo}
          />
          <VisualInfo />
        </section>
        <section className="profile-main">
          {/* Modal que pregunta por el expediente en caso de haberse registrado por facebook y twitter */}
          {/* <Modal
            visible={loggedProvider}
            title="¿Eres paciente?"
            onOk={() => {
              setLoggedProvider(false);
            }}
            onCancel={() => { 
              setLoggedProvider(false);
              foundLeadByMail();
            }}
            footer={[
              <Button 
                onClick={() => { 
                  foundLeadByMail();
                }}
              >
                No
              </Button>,
              <Button key="submit" type="primary" onClick={() => { 
                setLoggedProvider(false);
                setVisibleNumberWindow(true);
              }}>
                Si
            </Button>,
            ]}
          >
            <p> ¿ Tienes expediente ? </p>
          </Modal> */}

          {/* Modal de numero de expediente */}
          {/* <Modal
            title="Numero de Expediente"
            visible={visibleNumberWindow}
            onOk={() => {
              foundExpedientNumber();
            }}
            onCancel={() => {
              setVisibleNumberWindow(false);
              
            }}
          >
            <h3> Ingresa tu número de expediente </h3>
            <Input
              className="create-input"
              value={expedientNumber}
              onChange={e => {
                setExpedientNumber(e.target.value);
              }}
            />
          </Modal> */}

          {/* Modal de expediente no encontrado */}
          {/* <Modal
            title="Expediente no encontrado"
            visible={visibleTmWindow}
            onOk={() => {
              setVisibleTmWindow(false);
            }}
            onCancel={() => {
              setVisibleTmWindow(false);
            }}
            footer={[
              <Button key="submit" type="primary" onClick={() => { 
                setVisibleTmWindow(false);
              }}>
                Aceptar
            </Button>,
            ]}
          >
            <h3> Lo Sentimos </h3>
            <p>
              Tu número de expediente no ha sido encontrado en el sistema,por favor contacta al call center
            </p>
          </Modal> */}

          {/* Banner Principal */}
          <Banner />

          {/* Vista de tabla de visitas */}

          <DatesCalendar/>
          <TreatmentTable
            tabledata={tabledata}
            columns={columns}
          />
        </section>
      </div>
    </div>
  );
};

Profile.propTypes = {};

export default Profile;