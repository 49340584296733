import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

const GlaucomaSectionSeven = ({submitAnswer}) => {
    return (
        <Fragment>
            <div className="glaucomaSection">
                <div className="glaucoma-question">
                    <h1> ¿Has presentado dolor ocular punzante? </h1>
                    <div className="glaucoma-buttons">
                        <button
                          onClick={()=>{
                            submitAnswer(true);
                          }}
                        > Si </button>
                        <button
                         onClick={()=>{
                          submitAnswer(false);
                         }}
                        > No</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

GlaucomaSectionSeven.propTypes = {};
export default GlaucomaSectionSeven;