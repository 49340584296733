import React from 'react';
import CuadroRetinaUno from '../../images/cuadro_retina_uno.png';
import ImagenJorgeAbajo from '../../images/imagen_jorge_abajo.png';
import LogoFin from '../../images/logo_fin.png';

const RetinaSectionOne = () => {
    return (
      <div className="retinaSection">
        <header className="retinaHeader">
          Prueba de función de la retina
        </header>
        <div className="retina-inner-grid-two">
           <aside className="retina-left">
             <img src={LogoFin} />
           </aside>
           <aside className="retina-right">
             <h3> ¡Haz concluido el test! </h3>
             <p>
               Si alguna de tus respuestas es <strong> NO </strong>, podría ser indicio de una alteración en la mácula y requieres una revisión con el especialista, para un diagnóstico preciso.  
             </p>
             <div class="end-section-one">
               <strong> ¡Agenda una cita ahora mismo! </strong> 
               <button className="send-button-one"> 
                Hacer una cita
               </button>
             </div>
             <div className="finish-buttons">
                <button className="send-button-two">
                  Enviar resultado por correo
                </button> 
                <button className="send-button-three">
                  Terminar
                </button>   
             </div>
           </aside>
        </div>
      </div>
    );
};

export default RetinaSectionOne;


