import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

const GlaucomaSectionNine = ({submitAnswer}) => {
    return (
        <Fragment>
            <div className="glaucomaSection">
                <div className="glaucoma-question">
                    <h1>¿Que edad tienes? </h1>
                    <p> Selecciona una de las siguientes opciones </p>
                    <div className="glaucoma-optionsList">
                      <li className="glaucoma-option"
                        onClick={()=>{
                          submitAnswer('20 - 30 años');
                        }}
                      >
                        <span className="letter"> a  </span>  
                        20 - 30 años
                      </li>
                      <li className="glaucoma-option"
                        onClick={()=>{
                          submitAnswer('31 a 40 años ');
                        }}
                      >
                        
                          <span>
                            b  
                          </span>
                          31 a 40 años 
                        
                      </li>
                      <li className="glaucoma-option"
                        onClick={()=>{
                          submitAnswer('41 a 50 años');
                        }}
                      >
                       
                          <span>
                            c 
                          </span>
                          41 a 50 años
                        
                      </li>
                      <li className="glaucoma-option"
                        onClick={()=>{
                          submitAnswer(' 51 a 60 años');
                        }}
                      >
                        
                          <span>
                            d
                          </span>
                          51 a 60 años
                        
                      </li>
                      <li className="glaucoma-option"
                         onClick={()=>{
                          submitAnswer(' Más 60 años');
                        }}
                      >
                        
                          <span>
                            e
                          </span>
                          Más 60 años
                         
                      </li>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

GlaucomaSectionNine.propTypes = {};
export default GlaucomaSectionNine;