import React, { Fragment,useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

var moment = require('moment');
const axios = require('axios');


const DatesCalendar = () => {
    const [events, setEvents] = useState([]); 
    const [initialDate, setInitialDate] = useState(null);
    const [calendarSchedule, setCalendarSchedule] = useState([]);
    
    const getListParams = {
      idFirebase: localStorage.getItem('idFirebase'),
      action: 'login_calendario'
    };

    const renderCalendarSchedule = (calendar) => {
      const schedule = [];
      calendar.forEach(item=>{
        schedule.push({
          title: item.title, 
          date: item.date  
        });
      });
      setCalendarSchedule(schedule);
      setInitialDate(schedule[0]['date']);
    }

    const getEvents = async () => {
        const idFirebase = getListParams.idFirebase;
        const action = getListParams.action;
        try {
          const resp = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?idFirebase=${idFirebase}&action=${action}`);
          console.log('resultado de consultas',resp.data);
          setEvents(
           resp !== null && 
           resp.data !== undefined && 
           resp.data !== null ? 
           resp.data : []
          );
          if (resp !== null && resp.data !== undefined && resp.data !== null ) {
            renderCalendarSchedule(resp.data);
          }
        } catch (err) {
          // Handle Error Here
          console.error('error en peticion axios',err);
        }
    }
    
    useEffect(() => {
      getEvents();
    }, []);

    
    return (
        <Fragment>
            <header className="visual-header" style={{ marginTop: '25px' }}>
                Mis consultas
                <span className="visual-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="27.693" height="26.431" viewBox="0 0 27.693 26.431">
                        <g id="Grupo_4421" data-name="Grupo 4421" transform="translate(-1008.571 -292.13)">
                            <g id="Grupo_4414" data-name="Grupo 4414" transform="translate(1009.571 293.13)">
                                <path id="Trazado_26746" data-name="Trazado 26746" d="M1014.669,317.358a5.13,5.13,0,0,1-5.1-5.133V300.616a5.13,5.13,0,0,1,5.1-5.133h15.5a5.13,5.13,0,0,1,5.1,5.133v11.609a5.13,5.13,0,0,1-5.1,5.133h-9.342" transform="translate(-1009.571 -292.926)" fill="none" stroke="#2f778b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <line id="Línea_144" data-name="Línea 144" y2="5.198" transform="translate(7.934)" fill="none" stroke="#2f778b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <line id="Línea_145" data-name="Línea 145" y2="5.198" transform="translate(17.49)" fill="none" stroke="#2f778b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <line id="Línea_146" data-name="Línea 146" x2="25.693" transform="translate(0 8.884)" fill="none" stroke="#2f778b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            </g>
                            <g id="Grupo_4416" data-name="Grupo 4416" transform="translate(1020.407 305.67)">
                                <g id="Grupo_4415" data-name="Grupo 4415">
                                    <path id="Trazado_26747" data-name="Trazado 26747" d="M1020.776,305.625h-.023l-.712.487a.84.84,0,0,1-.474.147.652.652,0,0,1-.656-.667.812.812,0,0,1,.4-.667l1.209-.836a1.665,1.665,0,0,1,.961-.35.865.865,0,0,1,.881.96v6.023a.792.792,0,1,1-1.582,0Z" transform="translate(-1018.911 -303.739)" fill="#2f778b" />
                                </g>
                            </g>
                        </g>
                    </svg>

                </span>
            </header>
            <div className="calendarGrid">
                <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    dayHeaders={true}
                    dateAlignment={"month"}
                    dayHeaders={false}
                    events={calendarSchedule}
                    initialDate={initialDate}
                    locale={"es"}
                />
                <section className="calendar-info">
                    <header>
                        Citas del Mes
                 </header>
                    <ul className="calendarList">
                        {
                            events.map((item,index) => (
                                <li key={index}>
                                    <div className={`eventDay ${item.classNames}`} >
                                        <svg id="Planner_Checklist" data-name="Planner Checklist" xmlns="http://www.w3.org/2000/svg" width="24.547" height="26.899" viewBox="0 0 24.547 26.899">
                                            <rect id="Rectangle_32" data-name="Rectangle 32" width="24.547" height="24.547" fill="none" />
                                            <g id="Group_8" data-name="Group 8" transform="translate(2.06 2.221)">
                                                <g id="Group_5" data-name="Group 5" transform="translate(0)">
                                                    <g id="Group_4" data-name="Group 4">
                                                        <path id="Rectangle_2" data-name="Rectangle 2" d="M0,0H20.6V11.285c0,3.938-2.306,4.4-5.15,4.4H5.15c-2.844,0-5.15-.465-5.15-4.4Z" transform="translate(0 8.24)" fill="none" stroke="#a0a0a0" strokeMiterlimit="10" strokeWidth="1.5" />
                                                        <path id="Rectangle" d="M2.06,0H18.54A2.06,2.06,0,0,1,20.6,2.06V6.18H0V2.06A2.06,2.06,0,0,1,2.06,0Z" transform="translate(0 2.06)" fill="none" stroke="#a0a0a0" strokeMiterlimit="10" strokeWidth="1.5" />
                                                        <path id="Path_3" data-name="Path 3" d="M.833,0V3.09" transform="translate(15.647)" fill="none" stroke="#a0a0a0" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
                                                        <path id="Path_3-2" data-name="Path 3" d="M.833,0V3.09" transform="translate(4.317)" fill="none" stroke="#a0a0a0" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <span className="dayNumber"> 
                                          {moment(item.date).format('D')}
                                        </span>
                                        
                                    </div>
                                    <div className={`eventName ${item.classNames}`}>
                                        <p> {item.title} </p>
                                        <span> {item.extendedProps.clinica} </span>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </section>
            </div>

        </Fragment>
    );
};



export default DatesCalendar;