import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

const GlaucomaSectionOne = ({submitAnswer}) => {
  return (
    <Fragment>
      <div className="glaucomaSection">
        <div className="glaucoma-question">
          <h1> ¿Existe historia familiar de glaucoma? </h1>
          <div className="glaucoma-buttons">
            <button
              onClick={()=>{
                submitAnswer(true);
              }}
            > 
              Si 
            </button>
            <button
              onClick={()=>{
                submitAnswer(false);
              }}
            > 
              No
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

GlaucomaSectionOne.propTypes = {};

export default GlaucomaSectionOne;