import React, { Fragment, useState, useEffect, useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import AdminSidebar from '../components/AdminSidebar';
import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";
import Header from '../components/header';
import { Layout, Modal, Select, Popconfirm } from "antd";
import API_CONSTANTS from "../utils/apiConstamts";
/* import 'react-tabulator/lib/styles.css'; */
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ReactTabulator, reactFormatter } from 'react-tabulator';
import {getUserInfo} from '../utils/api';

var moment = require('moment');
const axios = require('axios');

const DatesView = ({ history }) => {
  const [eventList, setEventList] = useState([]);
  const [initialDate, setInitialDate] = useState(null);
  const [clinicasList, setClinicasList] = useState([]);
  const [showEditar, setShowEditar] = useState(false);
  const [unidad, setUnidad] = useState(null);
  const [dateTimes, setDateTimes] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedHorary, setSelectedHorary] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [showAgendar, setShowAgendar] = useState(false);
  const [selectedClinica, setSelectedClinica] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const { Content, Footer } = Layout;
  const { usuario } = useContext(Auth);
  const [calendarId, setCalendarId] = useState(null);
  const [servicioId, setServicioId] = useState(null);
  const [pacientDetail, setPacientDetail] = useState({});
  const [cancelDate, setCancelDate] = useState(false);
  const [servicesTypes, setServicesTypes] = useState([]); 
  const [visbleConfirm, setVisibleConfirm] = useState(false);
  const [datesSchedule, setDatesSchedule] = useState([]);
  const [firstDate, setFirstDate] = useState(null);

  const id_paciente = localStorage.getItem('id');

  //const user_id = JSON.parse(localStorage.getItem('id'));

  const { Option } = Select;

  const SimpleButton = props => {
    return <button 
      onClick={() => {
        const rowData = props.cell._cell.row.data;
        //const choiseClinic = filterClinic(rowData); 
        setUserDetail(rowData);
        const filteredClinics = clinicasList.filter(clinica=>{
          if(rowData.clinica === "Hamburgo"){
            return clinica.nombre === rowData.clinica + ' - Zona Rosa';
          } else {
            return clinica.nombre === rowData.clinica;
          }
        });
        getHorarios(filteredClinics[0]['servicioId'],filteredClinics[0]['calendarId']);
        setShowEditar(true);
      }}
        className="editDate"
      >
        Modificar Cita
      </button>;
  };

  const columns = [
    { title: "Fecha", field: "fechaCita", resize: false, formatter : function (cell){
      const data = cell.getData();
      return moment(data).format('L');
    }},
    { title: "Clinica", field: "clinica", resize: false },
    {title: "Concepto", field: "nombreServicio", resize: false },
    {title: " ", field: "idCitaAcuity", resize: false, formatter: 
      reactFormatter(  
        <SimpleButton 
          onSelect={(name) => {
            console.log('name',name);
          }}
        />
      )
    },
  ];

  const printEstatus = (status) => {
    const statusClass = status.toLowerCase();
    const button = `<button class="editDate" onClick="alert('hola');"> Modificar Cita </button>`;
    const layout = `
     <div class="dateStatus-holder">
      <div class="dateStatus ${statusClass}">
        ${status}
      </div>
      ${button}
     </div>`;
    return layout;
  }

  /* const renderTableColumns = (columns) => {
    let newArray = [];
    columns.forEach(column => {
      if (column.field === "fechaCita") {
        newArray.push({
          title: column.title,
          field: column.field,
  
          formatter: function (cell) {
            const data = cell.getData();
            return moment(data).format('L');
          }
        })
      } 
      else {
        newArray.push({
          title: column.title,
          field: column.field,
        })
      }
    })

    return newArray;
  }; */

  const getEventList = async () => {  
    try {
      const resp = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?action=consulta_citas&idPaciente=${id_paciente}`);
      const result = resp !== undefined && resp !== null && resp.data !== undefined && resp.data !== null ? resp.data : {};
      setEventList(result.citas !== null && result.citas !==undefined ? result.citas : []);
      renderDatesCalendar(result.citas);
    } catch (err) {
      // Handle Error Here
      console.error('error en peticion axios', err);
    }
  }

  const renderDatesCalendar = async (citas) => {
    console.log('cuadra de citas',citas);
    const calSchedule = []; 
    citas.forEach(cita=>{
      calSchedule.push({
        title: cita.nombreServicioParaPacientes,
        date:  moment(cita.fechaCita).format()
      }); 
    });
    setDatesSchedule(calSchedule);
    setFirstDate(calSchedule[0]['date']);
  }


  {/* Obtiene los datos de las clinicas */ }

  const getClinicas = async () => {
    try {
      const resp = await axios.post(`${API_CONSTANTS.services}?action=login_clinicas`);
      const result = resp && resp !== null && resp.data && resp.data !== null ? resp.data : [];
      console.log('clinicas', result);
      setClinicasList(result);
    } catch (err) {
      console.error('error en peticion axios', err);
    }
  }

  {/* Obtiene horrios de las clinicas */ }

  const getHorarios = async (serviceId = null, calendarId = null) => {
    try {
      const resp = await axios.post(`${API_CONSTANTS.services}?action=tiempos_sucursales&serviceId=${serviceId}&calendarId=${calendarId}`);
      const result = resp && resp !== null && resp.data && resp.data !== null && resp.data.dias &&
      resp.data.dias !== null ? resp.data.dias : {};
      setDateTimes(result);
    } catch (err) {
      console.error('error en peticion axios', err);
    }
  }

  /*Obtiene los servicios de los tipos de cita */
  
  const getIdCitas = async () => {
    const resp = await axios.post(`${API_CONSTANTS.services}?action=servicios_agenda`);
    setServicesTypes(resp !== null && resp.data !== undefined && resp.data !== null ? resp.data : []);
  }

  {/* Realiza la cancelacion de la cita */ }
  const handleCancelDate = async () => {
    try {
      const resp = await axios.post(`${API_CONSTANTS.services}?action=cancelar_presencial&citaId=${userDetail.idCitaAcuity}`);
      console.log('tu cita ha sido cancelada');
    } catch (err) {
      console.error('error en peticion axios', err);
    }
  }

  {/*Editar cita*/ }
  const handleEditDate = async () => {
      try{
        const resp = await axios.post(`${API_CONSTANTS.services}?action=editar_presencial&id=${userDetail.idCitaAcuity}&dateTime=${selectedHorary}`);
        console.log('tu cita ha sido cambiada');
        getEventList();
        setShowEditar(false);
      } catch(error){
        console.error('error en peticion axios', error); 
      }
  }

  {/* Crear cita */}
  const handleCreateDate = async () => {
    let fullName = pacientDetail['nombre'].split(' ');
    try {
      const resp = await axios.post(`${API_CONSTANTS.services}?action=agendar_login_presencial&nombre=${fullName[0]}&apellidos=${fullName[1]}${fullName[2]}&datetime=${selectedHorary}&telefono=${pacientDetail.telefono}&email=${pacientDetail.correo}&serviceId=${servicioId}&calendarId=${calendarId}&expediente=${pacientDetail.expediente}`);
      console.log('tu cita ha sido agendada');
      getEventList();
      setShowAgendar(false);
    } catch (err) {
      console.error('error en peticion axios', err);
    } 
  }
    

  {/*Prepara las opciones para el select de dias y horarios */ }

  const daysOptions = (obj = {}) => {
    return Object.keys(obj)
      .map(property => <option value={property}> {moment(property).format('ll')} </option>);
  }

  const horaryOptions = () => {
    if (!selectedDay || !dateTimes[selectedDay]) {
      return null;
    }
    return (dateTimes[selectedDay] ?? [])
      .map(horary => <option value={horary.completo}> {horary.corto} </option>);
  }

  const handleChange = (e) => {
    console.log('seleccionado', e.target.value);
    setUnidad(e.target.value);
  }

  const pacientInfo = async () => {
    const detail = await getUserInfo();
    setPacientDetail(detail); 
  }

  useEffect(() => {
    getEventList();
    getClinicas();
    pacientInfo();
    getIdCitas();
  }, []);

  useEffect(() => {
    if (usuario === null) {
      history.push(`/login`);
      console.log('usuario no logeado');
    }
  }, [history, usuario]);

  const handleChangeDay = e => {
    setSelectedDay(e.target.value);
    
  }

  const handleChangeHorary = e => {
    const day = dateTimes[`${selectedDay}`];
    setSelectedHorary(e.target.value);
    console.log('day',day);
    const filteredDay = day.filter(item=>{
      return item.completo === e.target.value;
    });
    const newCalendarId = filteredDay[0].calendarId;
    console.log('newServiceId',newCalendarId);
  };

  
  
  return (
    <Fragment>
      <Layout>
        <Header />
        <Content className="datesContent">
          <div className="admin-grid">
            <AdminSidebar />
            <div className="datesGrid">
              <section className="datesGrid-calendar">
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  dayHeaders={true}
                  dateAlignment={"month"}
                  initialView="dayGridMonth"
                  weekends={false}
                  events={datesSchedule}
                  initialDate={firstDate}
                  locale={"es"}
                />
              </section>

              {/* modal para editar cita */}
              <Modal title="Editar cita" visible={showEditar}
                onOk={() => { setShowEditar(false) }} 
                onCancel={() => { 
                  setShowEditar(false);
                  setSelectedDay(null);
                  setSelectedHorary(null); 
                }} 
                width={548} 
                className="modalEdit"
              >
                <article className="modal-dates-holder">
                  <h3 className="modalDatesTitle"> Modifica tu cita </h3>
                  <p style={{ textAlign: 'center' }}> Tu doctor de los ojos te está esperando. </p>
                  {/* <select style={{width: '80%', margin:'0 auto'}} onChange={handleChange}>
                    {clinicasList.map(item=>{
                      return <option value={item.calendarId}>
                         {item.nombre}
                      </option> 
                    })}
                  </select>, */}
                  <div className="date-info">
                    <h4> Salauno {userDetail.clinica} - Consulta en Clínica Segura </h4>
                    <p className="item-paraf long">
                      {userDetail.descripcion}
                    </p>
                    <p className="item-paraf">
                      {moment(userDetail.fechaCita).format('L')}
                    </p>
                    <p className="item-paraf">
                      {moment(userDetail.fechaCita).format('hh:mm')}
                    </p>
                    <p className="item-paraf">
                      {pacientDetail.nombre}
                    </p>
                    <p className="item-paraf"> {pacientDetail.telefono} </p>
                    <p className="item-paraf"> {pacientDetail.telefono} </p>
                    <p className="item-paraf"> $350 / Consulta Presencial </p>
                  </div>
                  <div className="dateSchedule">
                    <article className="schedule-item">
                      <h4> Selecciona un nuevo día para tu cita test </h4>
                      {daysOptions && daysOptions !== null ? (
                        <select className="schedule-select" onChange={handleChangeDay} value={selectedDay}>
                          <option value=""> Selecciona dia </option>
                          {daysOptions(dateTimes)}
                        </select>
                      ) : null}

                    </article>
                    <article className="schedule-item">
                      <h4> Selecciona un nuevo día para tu cita </h4>
                      <select className="schedule-select" onChange={handleChangeHorary} value={selectedHorary}>
                        <option value=""> Selecciona horario </option>
                        {horaryOptions()}
                      </select>
                    </article>
                    <div className="date-finish-buttons">
                      <Popconfirm 
                        title="Seguro que quieres cancelar la cita？" 
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        onConfirm={()=>{                        
                          handleCancelDate();
                          setVisibleConfirm(false);
                        }}
                        okText="Si" 
                        cancelText="No"
                        onCancel={()=>{setVisibleConfirm(false)}}
                      >
                        <button
                          className="cancel-date-button"
                          onClick={() => {
                            setVisibleConfirm(true);
                          }}
                        >
                          Cancelar
                        </button>
                      </Popconfirm>
                      <button
                        className="change-date-button"
                        onClick={() => {
                          handleEditDate();
                        }}
                      >
                        Editar
                       </button>
                    </div>
                  </div>
                </article>
              </Modal>

              {/* Modal para realizar cita */}
              <Modal title="Agendar una cita" 
                visible={showAgendar}
                onOk={() => { setShowAgendar(false) }} 
                onCancel={() => { 
                  setShowAgendar(false) 
                  setSelectedDay(null);
                  setSelectedHorary(null);
                }} 
                width={548} 
                className="modalEdit"
              >
                <article className="modal-dates-holder">
                  <h3 className="modalDatesTitle"> Agenda tu cita </h3>
                  <p style={{ textAlign: 'center' }}> Tu doctor de los ojos te está esperando. </p>
                  <div className="dateSchedule">

                    <article className="schedule-item">
                      <h4> ¿Qué tipo de cita necesitas? </h4>
                      <select 
                        className="schedule-select" 
                        onChange={e => { 
                          setSelectedService(e.target.value);
                        }} 
                        value={selectedService}
                      >
                        <option value=""> Selecciona tipo de cita</option>
                        {servicesTypes.map(service=>{
                          return <option value={service.serviceId}>
                            {service.nombre}
                          </option>
                        })}
                      </select>
                    </article>

                    <article className="schedule-item">
                      <h4> ¿A qué clínica quieres asistir? </h4>
                      <select className="schedule-select" 
                        onChange={e => {
                          setSelectedClinica(e.target.value);
                          const filteredClinic = clinicasList.filter(clinic =>{
                            return clinic.nombre === e.target.value;
                          });
                          getHorarios(
                            filteredClinic[0]['consulta']['servicioId'],
                            filteredClinic[0]['consulta']['calendarId']
                          );  
                          setServicioId(filteredClinic[0]['consulta']['servicioId']);
                          setCalendarId(filteredClinic[0]['consulta']['calendarId']);
                        }}
                        value={selectedClinica}
                      >
                        <option value=""> Elige clínica </option>
                        {clinicasList.map(clinica => {
                          return <option value={clinica.nombre}>
                            {clinica.nombre}
                          </option>
                        })}
                      </select>
                    </article>

                    <article className="schedule-item">
                      <h4> Selecciona un nuevo día para tu cita  </h4>
                      {daysOptions && daysOptions !== null ? (
                        <select className="schedule-select" onChange={handleChangeDay} value={selectedDay}>
                          <option value=""> Selecciona dia </option>
                          {daysOptions(dateTimes)}
                        </select>
                      ) : null}

                    </article>
                    <article className="schedule-item">
                      <h4> Selecciona un nuevo horario para tu cita </h4>
                      <select className="schedule-select" onChange={handleChangeHorary} value={selectedHorary}>
                        <option value=""> Selecciona horario </option>
                        {horaryOptions()}
                      </select>
                    </article>


                    <div className="date-finish-buttons">
                      <button
                        className="change-date-button"
                        onClick={() => {
                          handleCreateDate();
                        }}
                      >
                        Agendar Cita
                      </button>
                    </div>
                  </div>
                </article>
              </Modal>

              {/* tabla de citas */}
              <section className="dates-table">
                <header className="visual-header" style={{ marginTop: '15px' }}>
                  Mis Citas
               </header>
                <div className="calendarTable">
                  <div className="calendarTableHolder">
                    <ReactTabulator
                      data={eventList}
                      columns={columns}
                      options={{
                        layout: "fitColumns",
                      }}
                    />
                  </div>

                  <button
                    className="newDate"
                    onClick={() => {
                      setShowAgendar(true);
                      getClinicas();
                    }}
                  >
                    Hacer Nueva Cita
                  </button>
                </div>
              </section>
            </div>
          </div>
        </Content>
      </Layout>

    </Fragment>
  );
};



export default withRouter(DatesView);