import React, { Fragment, useEffect, useState, useContext } from 'react';
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator, reactFormatter} from 'react-tabulator';
import { Link } from 'react-router-dom';
import { Auth } from "../context/AuthContext";
import API_CONSTANTS from "../utils/apiConstamts";
var Tabulator = require('tabulator-tables');


const axios = require('axios');

const TreatmentTable = ({userId}) => {
    const [treatmentsData, setTreatmentsData] = useState([]);
    const [treatmentsColumns, setTreatmentsColumns] = useState([]);
    const { usuario } = useContext(Auth);
    const printIcon = function(ojo){ //plain text value
        const eyeClass = ojo.toLowerCase();
        return `
        <div class="eye ${eyeClass}">
          <p class="eyeName"> ${ojo} </p>
          <svg xmlns="http://www.w3.org/2000/svg" width="33.104" height="18.52" viewBox="0 0 33.104 18.52">
           <g id="Grupo_4447" data-name="Grupo 4447" transform="translate(1 1)">
            <path id="Trazado_26750" data-name="Trazado 26750" d="M420.886,654.783a8.78,8.78,0,1,1-8.763-8.259" transform="translate(-396.756 -646.524)" fill="none" stroke="#92929d" strokeLinecap="round" stroke-linejoin="round" strokeWidth="2"/>
            <path id="Trazado_26751" dataName="Trazado 26751" d="M414.1,654.2a3.064,3.064,0,1,1-3.064-3.063" transform="translate(-395.667 -645.939)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Trazado_26752" data-name="Trazado 26752" d="M412.122,663.044c-10.272,0-15.366-8.261-15.366-8.261h0s5.095-8.259,15.366-8.259,15.364,8.41,15.364,8.41" transform="translate(-396.756 -646.524)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
           </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="33.104" height="18.52" viewBox="0 0 33.104 18.52">
           <g id="Grupo_4447" data-name="Grupo 4447" transform="translate(1.374 1)">
            <path id="Trazado_26750" data-name="Trazado 26750" d="M403.357,654.783a8.78,8.78,0,1,0,8.763-8.259" transform="translate(-396.756 -646.524)" fill="none" stroke="#92929d" strokeLinecap="round" stroke-linejoin="round" strokeWidth="2"/>
            <path id="Trazado_26751" data-name="Trazado 26751" d="M407.969,654.2a3.064,3.064,0,1,0,3.064-3.063" transform="translate(-395.668 -645.939)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            <path id="Trazado_26752" dataName="Trazado 26752" d="M412.12,663.044c10.272,0,15.366-8.261,15.366-8.261h0s-5.095-8.259-15.366-8.259-15.364,8.41-15.364,8.41" transform="translate(-396.756 -646.524)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
           </g>
          </svg>
        </div>`;
    };

    const printEstatus = (estado) => {
      const statusClass = estado.toLowerCase();
      return `
       <div className="status ${statusClass}">
         ${estado}
       </div>
      `;
    }

    const printTratamiento = (tratamiento) => {
      console.log('tratamiento',tratamiento);
      let tratamitentoLnk;
      
      if (tratamiento.includes("Glaucoma")){
        tratamitentoLnk = "https://salauno.com.mx/tratamiento/glaucoma";
      } else if (tratamiento.indexOf("Catarata")) {
        tratamitentoLnk = "https://salauno.com.mx/tratamiento/catarata";
      } else if (tratamiento.indexOf("Óptica")) {
        tratamitentoLnk = "https://salauno.com.mx/especialidad/consulta-oftalmologica";
      } else {
        tratamitentoLnk = "";
      }
          
      return ` <div class="tratamiento-holder">
         <span> ${tratamiento} </span>
         <a href="${tratamitentoLnk}" target="_blank"> i </a>
       </div>`;
    } 
    
    const renderColumns = columns => {
        const newArray = [];
        const newColums = columns.filter(column => {
         return column.field !=="medico"   
        });
        newColums.forEach(column => {
            if (column.field === 'ojo') {
                newArray.push({
                    title: column.title,
                    field: column.field,
                    resize: false,
                    formatter: function (cell) {
                        const data = cell.getData();
                        return printIcon(data.ojo);
                    }
                });
            } else if(column.field === 'estado') {
                newArray.push({
                    title: column.title,
                    field: column.field,
                    resize: false,
                    formatter: function (cell) {
                      const data = cell.getData();  
                      return printEstatus(data.estado);
                    }
                });
            } else if(column.field === 'folio') {
              newArray.push({
                title: column.title,
                field: column.field,
                resize: false,
              });
            } else if(column.field === 'tratamiento') {
              newArray.push({
                title: column.title,
                field: column.field,
                resize: false,
                formatter: function (cell) {
                  const data = cell.getData();  
                  return printTratamiento(data.tratamiento);
                }
              });
            } else {
                newArray.push({
                    title: column.title,
                    field: column.field,
                    resize: false
                })
            }

        });
       return newArray;
    };
    
    const getTreatments = async () => {
        const usuario_id = usuario && usuario.uid && usuario !== null && usuario.uid !== null ? usuario.uid : null;
        try {
          const resp = await axios.post(`${API_CONSTANTS.services}?idFirebase=${usuario_id}&action=login_tratamientos`);
          setTreatmentsData(resp.data);
        } catch (err) {
          console.error('error en peticion axios',err);
        }  
    }

    const getTreatmentsColumns = async () => {
      const usuario_id = usuario && usuario.uid && usuario !== null && usuario.uid !== null ? usuario.uid : null;
      const resp = await axios.post(`${API_CONSTANTS.services}?idFirebase=${usuario_id}&action=login_tratamientos_tabla`);
      setTreatmentsColumns(resp.data);  
    }
    
    useEffect(() => {
      getTreatments(); 
      getTreatmentsColumns();
    }, []);
    
    
    return (
        <Fragment>
            
            <figure className="banner">
               
            </figure>

            <header className="visual-header" style={{ marginTop: '25px' }}>
                Mis Tratamientos
              <span className="visual-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.142" height="25.01" viewBox="0 0 26.142 25.01">
                        <g id="Grupo_4422" dataname="Grupo 4422" transform="translate(-1106.118 -300.966)">
                            <path id="Trazado_26748" dataname="Trazado 26748" d="M1110.086,308.094l.312-3.441c.1-1.083.684-1.823,1.308-1.653l17.051,4.655c.637.174,1.073,1.211.973,2.318l-.036.406" transform="translate(0 -1)" fill="none" stroke="#2f778b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            <path id="Trazado_26749" data-name="Trazado 26749" d="M1107.118,321.3v2.345a1.409,1.409,0,0,0,1.479,1.327l21.261,0a1.336,1.336,0,0,0,1.4-1.259V311.081a1.354,1.354,0,0,0-1.421-1.276h-12.312a2.387,2.387,0,0,0-2.416-1.711h-6.572a1.356,1.356,0,0,0-1.422,1.277V316" fill="none" stroke="#2f778b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        </g>
                    </svg>

                </span>
            </header>
            <div className="treatmentTable-holder">
              <div className="treatmentTable-container">
              {<ReactTabulator
                    data={treatmentsData}
                    columns={renderColumns(treatmentsColumns)}
                    options={{
                      layout:"fitColumns",
                    }}
                />}
              </div>
                
                <footer className="treatment-footer">
                    <Link to={'/tratamientos'}>  Ver Todo </Link>
                </footer>
            </div>



        </Fragment>
    );
};


export default TreatmentTable;