import React, { Fragment } from 'react';


const LoginHeader = () => {
    return (
        <Fragment>
            <header className="login-form-header">
                <figure className="login-logo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="166.336" height="125.383" viewBox="0 0 166.336 125.383">
                        <g id="Grupo_4454" data-name="Grupo 4454" transform="translate(-401.793 -184)">
                            <g id="Grupo_4450" data-name="Grupo 4450" transform="translate(445.331 290.803)" opacity="0.191">
                                <path id="Trazado_26732" data-name="Trazado 26732" d="M1164.932,605.155l-2.611-.2.248-.043c1.142-.2.85-.449-.636-.57a7.7,7.7,0,0,0,1.969-.251c2.447-.929-.04-1.782-4.548-2.455.694-.111,1.393-.218,2.08-.336,8.578-1.471,12.834-2.805,13.366-3.991,2-.038,4.087-.12,5.993-.2,2.19-.1,4.864-.194,6.59-.394,3.787-.438,1.247-1.087-3.9-1-2.243.043-4.533.122-6.721.216-.892.039-1.87.079-2.825.125a15.8,15.8,0,0,0-3.384-1.018c2.114-.159,4.161-.362,6-.558,2.191-.232,4.966-.5,6.211-.812,2.743-.691-2.779-1.229-8.46-.826-2.459.18-4.843.4-7.041.634-1.295.136-2.78.285-4.034.449-2.1-.241-4.486-.466-7.109-.678,1.512-.248,2.859-.525,4.059-.784,1.613-.349,3.752-.762,4.059-1.145.688-.84-7.04-1.1-11.862-.4-2.078.31-3.92.66-5.534,1.007-.84.181-1.811.379-2.6.583-19.66-.867-39.67.018-57.4,3.061-.454.078-.828.153-1.258.23-5.131-.514-6.225-1.434-4.059-2.308,1.169-.471-4.656-.677-5.838-.2-3.035,1.224-1.2,2.5,6.385,3.184-13.218,2.752-10.638,4.987,4.337,6.583l-10.246,1.81a7.894,7.894,0,0,1-2.172.089l-2.914-.214-1.657-.122c-1.333.212-2.7.437-3.018.5.421.072,3.186.472,5.619.653a39.325,39.325,0,0,0,10.192-.38l10.133-1.79c.379.03.72.063,1.111.093q1.888.144,3.788.265l-10.149,1.793c-3.174.561-2.838.926,1.133,1.22,2.378.177,6.568.3,7.512.318.408-.064,1.638-.295,2.771-.518l-1.656-.123-2.9-.218c-.638-.047-1.1-.1-.179-.262l10.378-1.834-.215-.01a285.22,285.22,0,0,0,51.23-2.068c2.867.467,4.47,1.03,2.893,1.628-.133.05-.17.1-.156.141l-.04,0a24.362,24.362,0,0,0-4.908.146l-.247.042-2.611-.2a40.959,40.959,0,0,0-8.237.245l-8.833,1.515c-.294.051-.215.117.178.146l22.819,1.753a6.157,6.157,0,0,0,1.243-.036l8.833-1.516C1168.053,605.788,1167.527,605.354,1164.932,605.155Zm-4.031-.31-6.767-.52.248-.042a11.954,11.954,0,0,1,2.421-.072l4,.307c.762.059.917.186.345.285Z" transform="translate(-1066.402 -590.515)" fill="#115e67" />
                            </g>
                            <g id="Grupo_4451" data-name="Grupo 4451" transform="translate(445.636 287.187)">
                                <g id="Grupo_4396" data-name="Grupo 4396" transform="translate(0)">
                                    <path id="Trazado_26733" data-name="Trazado 26733" d="M1079.26,587.467l.22,14.826c.068,4.573-1.677,6.552-5.839,6.614a26.118,26.118,0,0,1-6.9-1.257c-.044-.509,0-2.387.066-4.174l1.736-.029c1.013-.019,2.191-.045,3.047-.057.674-.01,1.232-.125,1.212-1.453l-.224-15.15a50.377,50.377,0,0,0,6.683.679m16.311,15.58c-1.016.01-2.2.02-3.049.033-.668.009-1.233-.088-1.252-1.416l-.222-14.952a52.212,52.212,0,0,1-6.666.733l.22,14.773c.068,4.573,1.871,6.5,6.033,6.437a25.285,25.285,0,0,0,6.855-1.478c.028-.539-.057-2.389-.18-4.152Z" transform="translate(-1066.723 -586.711)" fill="#255e67" />
                                </g>
                            </g>
                            <g id="Grupo_4452" data-name="Grupo 4452" transform="translate(401.793 233.589)">
                                <path id="Trazado_26734" data-name="Trazado 26734" d="M1043.859,543.081c-8.316,2.267-15.23-3.411-17.961-10.945-1.157-3.192-6.3-1.815-5.127,1.414,3.712,10.243,13.237,17.729,24.5,14.659,3.3-.9,1.9-6.03-1.414-5.128Z" transform="translate(-1020.599 -530.326)" fill="#255e67" />
                            </g>
                            <g id="Grupo_4453" data-name="Grupo 4453" transform="translate(419.852 184)">
                                <g id="Grupo_4400" data-name="Grupo 4400" transform="translate(66.126 62.25)">
                                    <path id="Trazado_26736" data-name="Trazado 26736" d="M1111.731,548.963c6.96-.032,16.456,2.172,18.97,9.519,1.1,3.219,6.24,1.836,5.128-1.415-3.355-9.8-14.531-13.468-24.1-13.422-3.421.016-3.427,5.335,0,5.318Z" transform="translate(-1109.163 -543.645)" fill="#255e67" />
                                </g>
                                <g id="Grupo_4402" data-name="Grupo 4402" transform="translate(0)">
                                    <path id="Trazado_26737" data-name="Trazado 26737" d="M1128.583,497.717a2.77,2.77,0,0,1,1.329.35,2.484,2.484,0,0,1,1.009,1,2.721,2.721,0,0,1,.006,2.7,2.5,2.5,0,0,1-1,1,2.712,2.712,0,0,1-2.688,0,2.523,2.523,0,0,1-1-1,2.71,2.71,0,0,1,.006-2.7,2.491,2.491,0,0,1,1.011-1,2.762,2.762,0,0,1,1.327-.35m0,.449a2.293,2.293,0,0,0-1.106.292,2.08,2.08,0,0,0-.843.833,2.253,2.253,0,0,0-.006,2.247,2.111,2.111,0,0,0,.836.833,2.244,2.244,0,0,0,2.24,0,2.1,2.1,0,0,0,.835-.833,2.269,2.269,0,0,0-.007-2.247,2.06,2.06,0,0,0-.842-.833,2.3,2.3,0,0,0-1.106-.292m-1.183,3.747v-2.907h1a2.426,2.426,0,0,1,.741.081.7.7,0,0,1,.365.28.747.747,0,0,1,.136.427.772.772,0,0,1-.227.554.9.9,0,0,1-.6.264.828.828,0,0,1,.247.154,3.282,3.282,0,0,1,.429.576l.355.57h-.574l-.258-.459a2.307,2.307,0,0,0-.49-.678.6.6,0,0,0-.375-.1h-.275v1.236Zm.469-1.637h.57a.922.922,0,0,0,.556-.122.418.418,0,0,0,.077-.552.426.426,0,0,0-.2-.152,1.445,1.445,0,0,0-.471-.05h-.532Z" transform="translate(-1043.864 -479.125)" fill="#ffcf00" />
                                    <g id="Grupo_4401" data-name="Grupo 4401">
                                        <path id="Trazado_26738" data-name="Trazado 26738" d="M1111.427,510.517a26.308,26.308,0,0,0,3.525-3.77c1.195-1.5,2.736-3.223,3.15-5.109.9-4.145-3.729-6.729-7.122-3.976a29.548,29.548,0,0,0-4,4.162c-.487.611-1.032,1.265-1.532,1.949a31.89,31.89,0,0,0-6.824-4.311,34.658,34.658,0,0,0,2.016-5.791c.611-2.279,1.511-5,1.081-7.332-.94-5.125-7.277-5.768-9.719-1a37.47,37.47,0,0,0-2.451,6.678,37.9,37.9,0,0,0-1.039,4.313,53.916,53.916,0,0,0-8.1-.746,46.264,46.264,0,0,0-.376-6.234c-.305-2.683-.5-5.969-1.91-8.275-3.085-5.081-9.835-3.16-10.383,2.92a44.664,44.664,0,0,0,.242,8.138c.158,1.4.295,2.951.585,4.445-18.095,3.66-28.973,17.427-28.973,42.467,0,29.423,15.167,43.326,39.536,43.326s39.541-13.9,39.541-43.326c0-12.113-2.575-21.553-7.248-28.529m-32.289,55.241c-12.995,0-19.858-9.021-19.858-26.714,0-17.872,6.863-26.894,19.858-26.894s19.854,9.023,19.854,26.894c0,17.693-6.856,26.714-19.854,26.714" transform="translate(-1039.598 -478.158)" fill="#ffcf00" />
                                        <path id="Trazado_26739" data-name="Trazado 26739" d="M1077.434,535.555v8a3.4,3.4,0,0,1-6.792,0v-8a3.4,3.4,0,1,1,6.792,0m6.481,0v8a3.393,3.393,0,1,0,6.786,0v-8a3.393,3.393,0,1,0-6.786,0" transform="translate(-1041.133 -480.827)" fill="#255e67" />
                                    </g>
                                </g>
                            </g>
                            <rect id="Rectángulo_3004" data-name="Rectángulo 3004" width="8.702" height="5.426" rx="0.601" transform="translate(472.962 272.181)" fill="#007488" />
                        </g>
                    </svg>

                </figure>
                <div className="header-text">
                    <h1> Bienvenido </h1>
                    <p> Ingresa tus datos para inciar sesión </p>
                </div>
            </header>
        </Fragment>
    );
};


export default LoginHeader;