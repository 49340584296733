import firebase from "firebase/app";
import app from "../firebaseConfig";


export const FirebaseErrorCodes = {
    'AUTH_EMAIL_ALREADY_IN-USE': 'auth/email-already-in-use'
}

export const createFirebaseUser = async ({ email, password }) => {
    const result = await app.auth().createUserWithEmailAndPassword(email, password);
    return result?.user;
}

export const asingAccountIdToFirebaseUser = async ({ uid, accountId }) => {
    const usersRef = firebase.firestore().collection('users');
    await usersRef.doc(uid).set({ id: accountId, idFirebase: uid });
}

export const loginWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    const result = await loginSocial({ provider });
    return result?.user;
}

export const loginWithFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    const result = await loginSocial({ provider });
    return result?.user;
}

const loginSocial = ({ provider }) => {
    return app.auth().signInWithPopup(provider);
}
