import React, {Fragment} from 'react';

const PencilComponent = () => {
    return (
       <Fragment>
         <div className="editPencil">
          <svg id="ic_Edit" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
            <path id="Shape" d="M.887,17a.891.891,0,0,1-.872-1.054l.8-4.252a.886.886,0,0,1,.246-.465L11.785.5A1.7,1.7,0,0,1,14.2.5l2.3,2.3a1.7,1.7,0,0,1,0,2.411L5.773,15.943a.893.893,0,0,1-.466.246l-4.253.8A.91.91,0,0,1,.887,17ZM10.734,4.067,2.51,12.291,2,15l2.7-.506,8.224-8.224ZM12.99,1.811l-1,1,2.2,2.2,1-1ZM16.114,17H8.938a.854.854,0,0,1-.886-.895.926.926,0,0,1,.221-.615.855.855,0,0,1,.664-.28h7.176a.854.854,0,0,1,.886.895.926.926,0,0,1-.221.615A.855.855,0,0,1,16.114,17Z" transform="translate(2 2)" fill="#92929d"/>
          </svg>
         </div>
       </Fragment>
    );
};


export default PencilComponent;