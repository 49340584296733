import React from "react";
import { BrowserRouter as HashRouter, Switch, Route,Redirect } from "react-router-dom";
import Dashboard from "./views/Dashboard";
import Login from "./views/login";
import SendPassword from "./views/SendPassword";
import Error from "./views/Error";
import DatesView from './views/DatesView';
import TreatmentsView from './views/TreatmentsView';
import DiagnosticsView from './views/DiagnosticsViews';
import Graduations from './views/Graduations';
import VisualControl from './views/VisualControl';
import GlassesView from './views/GlassesView';
import TestGlaucoma from './views/TestGlaucoma';
import TestRetina from './views/TestRetina';
import Prescriptions from './views/Prescriptions';
import UserInfo from './views/UserInfo';
import { AuthContext } from "./context/AuthContext";


const App = () => (
  <AuthContext>
    <HashRouter>
      <Redirect
        exact
        from="/"
        to="/inicio" />
      <Switch>
        <Route exact path={`/login`} component={Login} />
        <Route exact path={`/inicio`} component={Dashboard} />
        <Route exact path={`/recuperar-password`} component={SendPassword} />
        <Route exact  path={`/citas`} component={DatesView} />
        <Route exact path={`/tratamientos`} component={TreatmentsView} />
        <Route exact path={`/diagnosticos`} component={DiagnosticsView} />
        <Route exact path={`/graduaciones`} component={Graduations} />
        <Route exact path={`/prescripciones`} component={Prescriptions} />
        <Route exact path={`/control-visual`} component={VisualControl} />
        <Route exact path={`/mis-lentes`} component={GlassesView} />
        <Route exact path={`/test-glaucoma`} component={TestGlaucoma} />
        <Route exact path={`/test-retina`} component={TestRetina} />
        <Route exact path={`/mis-datos`} component={UserInfo} />
        <Route path="*" component={Error}/>
      </Switch>
    </HashRouter>
  </AuthContext>
);

export default App;