import React, { Fragment, useState, useEffect } from 'react';
import Header from '../components/header';
import { Layout } from "antd";
import AdminSidebar from '../components/AdminSidebar';
import { ReactTabulator } from 'react-tabulator';

const axios = require('axios');

const Prescriptions = () => {
    const [prescriptions, setPrescriptions] = useState([]);

    const getPrescriptions = async () => {
        try {
            const resp = await axios.post('https://salauno.testapps.mx/wp-admin/admin-ajax.php?idFirebase=2209&action=login_prescripciones');
            console.log('prescripciones', resp.data);
            setPrescriptions(resp.data);
        } catch (err) {
            console.error('error en peticion axios', err);
        }
    };

    const columns = [
        {title:'folio',field:'folio'},
        {title:'fecha',field:'fecha'},
        {title:'observaciones',field:'observaciones'},
        {title:'nombre',field:'nombre'},
        {title:'medicamento',field:'medicamento'},
        {title:'cantidad',field:'cantidad'}, 
    ]

    useEffect(() => {
        getPrescriptions();
    }, []);

    return (
        <Fragment>
            <Header />
            <Layout style={{ height: '100%' }}>
                <div className="tratments-grid">
                    <AdminSidebar />
                    <section className="treatments-holder">
                        
                        <div className="treat-table-container">
                        <header className="visual-header" style={{ marginTop: '25px' }}>
                            Prescripciones
                              <span className="visual-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26.142" height="25.01" viewBox="0 0 26.142 25.01">
                                    <g id="Grupo_4422" dataname="Grupo 4422" transform="translate(-1106.118 -300.966)">
                                        <path id="Trazado_26748" dataname="Trazado 26748" d="M1110.086,308.094l.312-3.441c.1-1.083.684-1.823,1.308-1.653l17.051,4.655c.637.174,1.073,1.211.973,2.318l-.036.406" transform="translate(0 -1)" fill="none" stroke="#2f778b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                        <path id="Trazado_26749" data-name="Trazado 26749" d="M1107.118,321.3v2.345a1.409,1.409,0,0,0,1.479,1.327l21.261,0a1.336,1.336,0,0,0,1.4-1.259V311.081a1.354,1.354,0,0,0-1.421-1.276h-12.312a2.387,2.387,0,0,0-2.416-1.711h-6.572a1.356,1.356,0,0,0-1.422,1.277V316" fill="none" stroke="#2f778b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    </g>
                                </svg>

                            </span>
                        </header>
                            {<ReactTabulator
                                data={[]}
                                columns={columns}
                                resize={false}
                                height={'100%'}
                            />}
                        </div>
                    </section>
                </div>
            </Layout>
        </Fragment>
    );
};

export default Prescriptions;