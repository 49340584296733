import React, { Fragment,useState,useEffect,useRef,useContext} from 'react';
import Header from '../components/header';
import AdminSidebar from '../components/AdminSidebar';
import { Form, Input, Layout, notification } from 'antd';
import banner4 from '../images/banner4.png';
import generic_user from '../images/generic_user.png';
import PencilComponent from '../components/PencilComponent';
import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";
import API_CONSTANTS from '../utils/apiConstamts';

var moment = require('moment');
const axios = require('axios');

const UserInfo = ({history}) => {
    const [nombre, setNombre] = useState(null);
    const [apellidos, setApellidos] = useState(null);
    const [rfc, setRfc] = useState(null);
    const [numeroInapam, setNumeroInapam] = useState(null);
    const [genero, setGenero] = useState(null);
    const [email, setEmail] = useState(null);
    const [cp, setCp] = useState(null);
    const [direccion, setdireccion] = useState('Jardines del Pedregal No. 1245');
    
    const [fechaNacimiento, setFechaNacimiento] = useState(null);
    const [edad, setEdad] = useState(null);
    const [telefono, setTelefono] = useState(null);
    
    const [activeTab, setActiveTab] = useState('datos-personales');
    const [ocupacion, setOcupacion] = useState('programador');
    const [disabledField, setDisabledField] = useState(true);
    const [userId, setUserId] = useState(null);
    
    
    const datosPersonales = useRef(null);
    const datosAtencion = useRef(null);
    const datosContacto = useRef(null);
    const estiloVida = useRef(null);
    const alergias = useRef(null);

    const { usuario } = useContext(Auth);


    const executeScroll = (paramRef,activeTab) =>{
      paramRef.current.scrollIntoView({behavior: "smooth"});
      setActiveTab(activeTab);
    };

    const getListParams = {
        action: "login_info",
        id_Firebase: localStorage.getItem('id')
      };
  
      const getUserInfo = async () => {
        const usuario_id = usuario && usuario.uid && usuario !== null && usuario.uid !== null ? usuario.uid : null;
        const userIdent = localStorage.getItem('idFirebase');
        console.log('userIdent', userIdent);
        console.log('url a llamar',`${API_CONSTANTS.services}?action=login_info&id_Firebase=${userIdent}`);
          try {
              const resp = await axios.post(`${API_CONSTANTS.services}?action=login_info&id_Firebase=${userIdent}`);
              const result = resp.data;
              console.log('result en vista de datos',result);
              setNombre(
                result.pacientes !== null &&
                result.pacientes !==undefined  ?
                result.pacientes[0]['nombre'] : 
                null
              );
              setApellidos(
                result.pacientes !== null && 
                result.pacientes !== undefined  ? 
                result.pacientes[0].inicialesNombre : null
              );
              
              setGenero(
                result.pacientes !== null && 
                result.pacientes !== undefined &&
                result.pacientes !== null &&
                result.pacientes[0] !== undefined &&
                result.pacientes[0] !== null ? 
                result.pacientes[0]['genero'] : null
              );
              setFechaNacimiento(
                result.pacientes !== null && 
                result.pacientes !== undefined &&
                result.pacientes !== null &&
                result.pacientes[0] !== undefined &&
                result.pacientes[0] !== null ? 
                result.pacientes[0]['fechaNacim'] : null
              );
              setTelefono(
                result.pacientes !== null && 
                result.pacientes !== undefined &&
                result.pacientes !== null &&
                result.pacientes[0] !== undefined &&
                result.pacientes[0] !== null ? 
                result.pacientes[0]['telefono'] : ""
              );
              setEmail(
                result.pacientes !== null && 
                result.pacientes !== undefined &&
                result.pacientes !== null &&
                result.pacientes[0] !== undefined &&
                result.pacientes[0] !== null ?  
                result.pacientes[0]['correo'] : ""
              );
              setCp(result.pacientes !== null && 
                result.pacientes !== undefined &&
                result.pacientes !== null &&
                result.pacientes[0] !== undefined &&
                result.pacientes[0] !== null ? result['pacientes'][0]['cp'] : null);
              setdireccion(
                result.pacientes !== null && 
                result.pacientes !== undefined &&
                result.pacientes !== null &&
                result.pacientes[0] !== undefined &&
                result.pacientes[0] !== null ? 
                result.pacientes[0]['direccion'] : null
              );
          } catch (err) {
              // Handle Error Here
              console.error('error en peticion axios',err);
          }
      };

      const updateAccount = async (updateParams={}) => {
        try{
          const resp = await axios.post(`${API_CONSTANTS.services_fb}?action=${getListParams.action}&id_Firebase=${getListParams.id_Firebase}`);

          const message = "Datos actualizados correctamente";
          const description = "Tus datos fueron actualizados correctamente";
          openNotification(message, description);
          getUserInfo();
        } catch(error) {
          const message = "Error al actualizar el perfil";
          const description = "Hubo un error al actualizar tu información";
          openNotification(message, description);
          console.error('error en peticion axios',error);
        }
      }

      const openNotification = (message,description) => {
        notification.open({
          message: message,
          description:description,
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
      };
      
      

    useEffect(() => {
      if (usuario === null) {
        history.push(`/login`);
        console.log('usuario no logeado');
      }
      setUserId(usuario && usuario.uid && usuario !== null && usuario.uid !== null ? usuario.uid : null);
    }, [usuario,history]);


    useEffect(() => {
      getUserInfo(); 
    }, []);
      
    console.log('nombre',nombre);
    
    return (
        <Fragment>
            <Header />
            <Layout style={{height:'100%'}}>
            <div className="admin-grid">
                <AdminSidebar />
                <section className="user-info-holder">
                    <section className="user-info-left">
                        <div className="user-photo">
                            <img src={generic_user} alt="photo" />
                        </div>
                        <ul>
                            <li 
                              onClick={()=>{executeScroll(datosPersonales,'datos-personales')}}
                              className={activeTab === 'datos-personales' ? 'data-option selected':'data-option'} 
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.107" height="20.105" viewBox="0 0 12.107 20.105">
                                    <g id="Grupo_4428" data-name="Grupo 4428" transform="translate(-820.008 -517.345)">
                                        <g id="Grupo_4426" data-name="Grupo 4426" transform="translate(823.312 518.095)">
                                            <path id="Trazado_26753" data-name="Trazado 26753" d="M843.516,520.845a2.75,2.75,0,1,1-2.75-2.75A2.748,2.748,0,0,1,843.516,520.845Z" className="infoIcon" transform="translate(-838.017 -518.095)" fill="none" stroke="#424445" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </g>
                                        <g id="Grupo_4427" data-name="Grupo 4427" transform="translate(820.758 525.628)">
                                            <path id="Trazado_26754" data-name="Trazado 26754" d="M831.365,580.076v-5.781a5.3,5.3,0,0,0-10.607,0v5.781" transform="translate(-820.758 -569.004)" fill="none" className="infoIcon" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </g>
                                    </g>
                                </svg>
                              Datos Personales
                            </li>
                            <li 
                              onClick={()=>{executeScroll(datosContacto,'datos-contacto')}}
                              className={activeTab === 'datos-contacto' ? 'data-option selected':
                              'data-option'}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.285" height="15.288" viewBox="0 0 15.285 15.288">
                                    <path id="Trazado_26755" data-name="Trazado 26755" d="M514.676,517.046m0,0a.221.221,0,0,1-.309.057c-.243-.133-.5-.24-.736-.385a11.655,11.655,0,0,1-2.81-2.557,6.659,6.659,0,0,1-1.007-1.613.231.231,0,0,1,.057-.3c.376-.363.742-.736,1.111-1.108a1.067,1.067,0,0,0,0-1.645c-.294-.3-.587-.587-.881-.884s-.6-.609-.909-.909a1.07,1.07,0,0,0-1.607,0c-.379.373-.742.754-1.127,1.121a1.83,1.83,0,0,0-.575,1.234,5.228,5.228,0,0,0,.4,2.251,13.673,13.673,0,0,0,2.428,4.044,15.021,15.021,0,0,0,4.975,3.893,7.2,7.2,0,0,0,2.756.8,2.021,2.021,0,0,0,1.733-.66c.322-.36.685-.688,1.026-1.032a1.074,1.074,0,0,0,.006-1.635q-.9-.9-1.806-1.8h0m0,0" transform="translate(-505.12 -506.519)" fill="none" stroke="#424445" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </svg>
                          Datos de contacto
                        </li>
                        {/* <li 
                          className={activeTab === 'datos-atencion' ? 'data-option selected':
                          'data-option'}
                          onClick={()=>{executeScroll(datosAtencion,'datos-atencion')}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.618" height="17.602" viewBox="0 0 22.618 17.602">
                                    <g id="Grupo_4431" data-name="Grupo 4431" transform="translate(-1403.72 -302.902)">
                                        <path id="Trazado_26756" data-name="Trazado 26756" d="M1418.415,306.989h4.8a2.383,2.383,0,0,1,2.376,2.376V318.1a2.383,2.383,0,0,1-2.376,2.376h-16.367a2.383,2.383,0,0,1-2.376-2.376v-8.733a2.383,2.383,0,0,1,2.376-2.376h4.752" transform="translate(0 -0.72)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26757" data-name="Trazado 26757" d="M1422.25,306.989" transform="translate(-3.835 -0.72)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26758" data-name="Trazado 26758" d="M1412.612,306.989" transform="translate(-1.756 -0.72)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <g id="Grupo_4429" data-name="Grupo 4429" transform="translate(1408.959 309.048)">
                                            <path id="Trazado_26759" data-name="Trazado 26759" d="M1412.435,311.654a1.121,1.121,0,1,1-1.121-1.122A1.12,1.12,0,0,1,1412.435,311.654Z" transform="translate(-1410.193 -310.532)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </g>
                                        <g id="Grupo_4430" data-name="Grupo 4430" transform="translate(1407.917 312.664)">
                                            <path id="Trazado_26760" data-name="Trazado 26760" d="M1413.19,319.656V317.3a2.162,2.162,0,0,0-4.325,0v2.357" transform="translate(-1408.865 -315.142)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </g>
                                        <line id="Línea_147" data-name="Línea 147" x2="6.415" transform="translate(1415.757 311.535)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Línea_148" data-name="Línea 148" x2="6.415" transform="translate(1415.757 314.809)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26761" data-name="Trazado 26761" d="M1417.57,307.941h0a1.324,1.324,0,0,1-1.32-1.32v-1.65a1.323,1.323,0,0,1,1.32-1.319h0a1.324,1.324,0,0,1,1.32,1.319v1.65A1.324,1.324,0,0,1,1417.57,307.941Z" transform="translate(-2.541)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </g>
                                </svg>
                          Datos de atención
                       </li> */}
                            {/* <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.445" height="18.715" viewBox="0 0 16.445 18.715">
                                    <g id="Grupo_4432" data-name="Grupo 4432" transform="translate(576.358 -588.584)">
                                        <g id="Grupo_4432-2" data-name="Grupo 4432" transform="translate(-573.918 594.233)">
                                            <path id="Trazado_26762" data-name="Trazado 26762" d="M-558.865,629.131a1.819,1.819,0,0,1-1.82,1.819,1.819,1.819,0,0,1-1.82-1.819,1.82,1.82,0,0,1,1.82-1.82A1.82,1.82,0,0,1-558.865,629.131Z" transform="translate(562.505 -627.311)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </g>
                                        <g id="Grupo_4433" data-name="Grupo 4433" transform="translate(-575.608 599.22)">
                                            <path id="Trazado_26763" data-name="Trazado 26763" d="M-568.586,673.293v-3.827a3.507,3.507,0,0,0-3.511-3.5,3.507,3.507,0,0,0-3.511,3.5v3.827" transform="translate(575.608 -665.964)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </g>
                                        <g id="Grupo_4434" data-name="Grupo 4434" transform="translate(-567.398 589.334)">
                                            <path id="Trazado_26764" data-name="Trazado 26764" d="M-507.369,591.633a2.3,2.3,0,0,1-2.3,2.3,2.3,2.3,0,0,1-2.3-2.3,2.3,2.3,0,0,1,2.3-2.3A2.3,2.3,0,0,1-507.369,591.633Z" transform="translate(511.969 -589.334)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </g>
                                        <g id="Grupo_4435" data-name="Grupo 4435" transform="translate(-569.475 595.585)">
                                            <path id="Trazado_26765" data-name="Trazado 26765" d="M-519.257,647.128v-4.913a4.431,4.431,0,0,0-4.435-4.425,4.424,4.424,0,0,0-2.094.524,4.416,4.416,0,0,0-2.283,3.908v.063" transform="translate(528.069 -637.79)" fill="none" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </g>
                                    </g>
                                </svg>


                         Antecedentes patológicos
                       </li> */}
                        {/* <li 
                          onClick={()=>{executeScroll(estiloVida,'estilo-vida')}}
                          className={activeTab === 'estilo-vida' ? 'data-option selected':
                          'data-option'}
                        >
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.832" height="18.935" viewBox="0 0 19.832 18.935">
                                    <path id="Trazado_26766" data-name="Trazado 26766" d="M1315.07,514.872l2.832,5.74,6.333.92L1319.654,526l1.082,6.308-5.665-2.978-5.665,2.978,1.081-6.308-4.583-4.467,6.334-.92Z" transform="translate(-1305.154 -514.122)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </svg>

                        Estilo de vida
                       </li>
                        <li 
                          className={activeTab === 'datos-alergias' ? 'data-option selected':
                          'data-option'}
                          onClick={()=>{executeScroll(alergias,'datos-alergias')}}
                        >
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.481" height="16.251" viewBox="0 0 18.481 16.251">
                                    <g id="Grupo_4437" data-name="Grupo 4437" transform="translate(0.786 0.421)">
                                        <path id="Trazado_26769" data-name="Trazado 26769" d="M1117.786,753.026a22.629,22.629,0,0,0,6.14-.724c4.343-1.134,7.428-3.369,9.17-6.644" transform="translate(-1117.786 -742.926)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <circle id="Elipse_73" data-name="Elipse 73" cx="1.987" cy="1.987" r="1.987" transform="translate(8.729 0.329)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <circle id="Elipse_74" data-name="Elipse 74" cx="1.987" cy="1.987" r="1.987" transform="translate(8.199 11.851) rotate(-42.716)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <circle id="Elipse_75" data-name="Elipse 75" cx="1.296" cy="1.296" r="1.296" transform="matrix(0.16, -0.987, 0.987, 0.16, 4.661, 6.574)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <circle id="Elipse_76" data-name="Elipse 76" cx="0.896" cy="0.896" r="0.896" transform="matrix(0.924, -0.383, 0.383, 0.924, 4.783, 0.686)" fill="rgba(0,0,0,0)" />
                                        <circle id="Elipse_77" data-name="Elipse 77" cx="0.896" cy="0.896" r="0.896" transform="translate(0.354 7.016) rotate(-76.718)" fill="rgba(0,0,0,0)" />
                                        <path id="Trazado_26770" data-name="Trazado 26770" d="M1128.182,742.552a.5.5,0,1,1-.7.04A.5.5,0,0,1,1128.182,742.552Z" transform="translate(-1125.883 -740.192)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26771" data-name="Trazado 26771" d="M1212.551,781.939a1.3,1.3,0,1,1-.028-1.832A1.3,1.3,0,0,1,1212.551,781.939Z" transform="translate(-1196.096 -771.769)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <circle id="Elipse_78" data-name="Elipse 78" cx="0.896" cy="0.896" r="0.896" transform="translate(4.632 11.615) rotate(-0.865)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </g>
                                </svg>

                        Alérgias
                       </li> */}
                            {/* <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.495" height="16.54" viewBox="0 0 15.495 16.54">
                                    <g id="Grupo_4440" data-name="Grupo 4440" transform="translate(287.143 87.991)">
                                        <path id="Trazado_26776" data-name="Trazado 26776" d="M-259.722-66.815a6.941,6.941,0,0,0,3.912-1.146,6.973,6.973,0,0,0,2-9.657,6.973,6.973,0,0,0-9.657-2" transform="translate(-19.745 -5.386)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26777" data-name="Trazado 26777" d="M-259.722-66.815a6.941,6.941,0,0,0,3.912-1.146,6.973,6.973,0,0,0,2-9.657,6.973,6.973,0,0,0-9.657-2" transform="translate(-19.745 -5.386)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26778" data-name="Trazado 26778" d="M-285.3-57.448a6.95,6.95,0,0,0-.9,5.26,6.964,6.964,0,0,0,4.046,4.864" transform="translate(0 -25.482)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26779" data-name="Trazado 26779" d="M-264.828-84.157l-2.208-.67.9-2.184" transform="translate(-16.672)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26780" data-name="Trazado 26780" d="M-257.184-49.258l0,2.846-3.8,2.634" transform="translate(-21.895 -32.541)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </g>
                                </svg>


                                <svg xmlns="http://www.w3.org/2000/svg" width="10.968" height="25.609" viewBox="0 0 10.968 25.609">
                                    <g id="Grupo_4439" data-name="Grupo 4439" transform="translate(-405.38 -738.905)">
                                        <path id="Trazado_26772" data-name="Trazado 26772" d="M427.579,741.444a1.79,1.79,0,1,1-1.79-1.789A1.79,1.79,0,0,1,427.579,741.444Z" transform="translate(-14.926)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26773" data-name="Trazado 26773" d="M415.6,784.43v-5.057a4.408,4.408,0,0,0-1.123-3.021,3.655,3.655,0,0,0-2.921-1.066h-1.381a3.657,3.657,0,0,0-2.921,1.066,4.413,4.413,0,0,0-1.123,3.021v5.057" transform="translate(0 -29.76)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26774" data-name="Trazado 26774" d="M418.976,805.915V817.84a1.266,1.266,0,0,0,1.266,1.267,1.341,1.341,0,0,0,1.351-1.267v-5.817" transform="translate(-10.729 -55.343)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26775" data-name="Trazado 26775" d="M437.479,805.915V817.84a1.266,1.266,0,0,1-1.266,1.267,1.341,1.341,0,0,1-1.351-1.267v-5.817" transform="translate(-23.998 -55.343)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </g>
                                </svg>


                                Antecedentes personales
                            </li> */}
                            {/* <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.495" height="16.54" viewBox="0 0 15.495 16.54">
                                    <g id="Grupo_4441" data-name="Grupo 4441" transform="translate(287.143 87.991)">
                                        <path id="Trazado_26776" data-name="Trazado 26776" d="M-259.722-66.815a6.941,6.941,0,0,0,3.912-1.146,6.973,6.973,0,0,0,2-9.657,6.973,6.973,0,0,0-9.657-2" transform="translate(-19.745 -5.386)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26777" data-name="Trazado 26777" d="M-259.722-66.815a6.941,6.941,0,0,0,3.912-1.146,6.973,6.973,0,0,0,2-9.657,6.973,6.973,0,0,0-9.657-2" transform="translate(-19.745 -5.386)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26778" data-name="Trazado 26778" d="M-285.3-57.448a6.95,6.95,0,0,0-.9,5.26,6.964,6.964,0,0,0,4.046,4.864" transform="translate(0 -25.482)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26779" data-name="Trazado 26779" d="M-264.828-84.157l-2.208-.67.9-2.184" transform="translate(-16.672)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26780" data-name="Trazado 26780" d="M-257.184-49.258l0,2.846-3.8,2.634" transform="translate(-21.895 -32.541)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </g>
                                </svg>


                                <svg xmlns="http://www.w3.org/2000/svg" width="20.112" height="11.355" viewBox="0 0 20.112 11.355">
                                    <g id="Grupo_4442" data-name="Grupo 4442" transform="translate(-395.726 -645.774)">
                                        <path id="Trazado_26781" data-name="Trazado 26781" d="M403.357,651.451a4.928,4.928,0,1,0,4.927-4.927" transform="translate(-2.363)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26782" data-name="Trazado 26782" d="M407.969,653.1a1.967,1.967,0,1,0,1.967-1.966" transform="translate(-4.014 -1.651)" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26783" data-name="Trazado 26783" d="M405.921,656.379a11.288,11.288,0,0,0,9.167-4.928h0a10.935,10.935,0,0,0-18.332.09" fill="rgba(0,0,0,0)" stroke="#424445" stroke-linecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </g>
                                </svg>


                                Antecedentes oftalmológicos
                            </li> */}
                        </ul>
                    </section>
                    <section className="user-info-right">
                       <figure className="banner">
                         <img src={banner4} alt=""/>
                       </figure>
                        <Form name="control-hooks">
                            <header className="fieldHeader" ref={datosPersonales}>
                                Datos Personales 
                            </header>
                            <div className="formField personalData-one">
                                <Form.Item label="Nombre" className="inputHolder">
                                    <Input 
                                      value={nombre}
                                      disabled={disabledField}
                                      onChange={(event)=>{
                                        setNombre(event.target.value);
                                      }}
                                    />
                                    <button className="pencilButton">
                                      <PencilComponent /> 
                                    </button>
                                </Form.Item>
                                <Form.Item label="Apellidos" className="inputHolder">
                                    <Input 
                                      value={apellidos}
                                      disabled={disabledField}
                                      onChange={(event)=>{
                                        setApellidos(event.target.value);
                                      }}
                                    />
                                    <button 
                                      className="pencilButton"
                                      onClick={()=>{
                                        setDisabledField(!disabledField);   
                                      }}
                                    >
                                      <PencilComponent /> 
                                    </button>
                                </Form.Item>
                            </div>
                            {/* <div className="formField personalData-two">
                                <Form.Item label="RFC" className="inputHolder">
                                    <Input 
                                      value={rfc}
                                      disabled={true}
                                      onChange={(event)=>{
                                        setRfc(event.target.value);
                                      }}
                                    />
                                </Form.Item>
                                <Form.Item label="Número de INAPAM" className="inputHolder">
                                    <Input 
                                      value={numeroInapam}
                                      disabled={true}
                                      onChange={(event)=>{
                                        setNumeroInapam(event.target.value);
                                      }}
                                    />
                                </Form.Item>
                            </div> */}
                            <div className="formField personalData-three">
                                <Form.Item label="Género" className="inputHolder">
                                    <Input 
                                      value={genero}
                                      disabled={disabledField}
                                      onChange={(event)=>{
                                        setGenero(event.target.value);
                                      }}
                                    />
                                    <button 
                                      className="pencilButton"
                                      onClick={()=>{
                                        setDisabledField(!disabledField);   
                                      }}
                                    >
                                      <PencilComponent /> 
                                    </button>
                                </Form.Item>
                                <Form.Item label="Fecha de Nacimiento" className="inputHolder">
                                    <Input 
                                      value={fechaNacimiento}
                                      disabled={disabledField}
                                      onChange={(event)=>{
                                        setFechaNacimiento(event.target.value);
                                      }} 
                                    />
                                    <button 
                                      className="pencilButton"
                                      onClick={()=>{
                                        setDisabledField(!disabledField);   
                                      }}
                                    >
                                      <PencilComponent /> 
                                    </button>
                                </Form.Item>
                                <Form.Item label="Edad" className="inputHolder">
                                    <Input 
                                      value={edad}
                                      disabled={true}
                                      onChange={(event)=>{
                                        setNombre(event.target.value);
                                      }}
                                    />
                                </Form.Item>
                            </div>
                            <header className="fieldHeader" ref={datosContacto}>
                                Datos de Contacto
                            </header>
                            <div className="formField contactData">
                                <Form.Item label="Teléfono" className="inputHolder">
                                    <Input value={telefono} onChange={(event) => {
                                      console.log(event.target.value);  
                                      setTelefono(event.target.value);
                                    }}/>
                                    <button 
                                      className="pencilButton"
                                      onClick={()=>{
                                        setDisabledField(!disabledField);   
                                      }}
                                    >
                                      <PencilComponent /> 
                                    </button>
                                </Form.Item>
                                <Form.Item label="Correo Electrónico" className="inputHolder">
                                    <Input 
                                      value={email}
                                      disabled={disabledField}
                                      onChange={(event)=>{
                                        setNombre(event.target.value);
                                      }}
                                    />
                                    <button 
                                      className="pencilButton"
                                      onClick={()=>{
                                        setDisabledField(!disabledField);   
                                      }}
                                    >
                                      <PencilComponent /> 
                                    </button>
                                </Form.Item>

                            </div>
                            <div className="formField contactData">
                                <Form.Item label="Código Postal" className="inputHolder">
                                    <Input  
                                      value={cp}
                                      disabled={disabledField}
                                      onChange={(event)=>{
                                        setCp(event.target.value);
                                      }}
                                    />
                                    <button 
                                      className="pencilButton"
                                      onClick={()=>{
                                        setDisabledField(!disabledField);   
                                      }}
                                    >
                                      <PencilComponent /> 
                                    </button>
                                </Form.Item>
                                <Form.Item label="Dirección" className="inputHolder">
                                    <Input 
                                      value={direccion}
                                      disabled={disabledField}
                                      onChange={(event)=>{
                                       setdireccion(event.target.value);
                                      }} 
                                    />
                                </Form.Item>
                            </div>
                           {/*  <header className="fieldHeader" ref={datosAtencion}>
                                Datos de Atención
                            </header>
                            <div className="formField atentionData-one">
                                <Form.Item label="Número de cuenta" className="inputHolder">
                                    <Input 
                                      value={numeroCuenta}
                                      disabled={true}
                                      onChange={(event)=>{
                                        setNumeroCuenta(event.target.value);
                                      }}
                                    />
                                </Form.Item>
                                <Form.Item label="Código Único" className="inputHolder">
                                    <Input 
                                      value={codigoUnico}
                                      disabled={disabledField}
                                      onChange={(event)=>{
                                        setCodigoUnico(event.target.value);
                                      }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="formField atentionData-two">
                                <Form.Item label="Aseror Responsable" className="inputHolder">
                                    <Input 
                                      value={'Rodrigo Salinas'}
                                      disabled={true}
                                      onChange={(event)=>{
                                        setAsesorResp(event.target.value);
                                      }}
                                    />
                                </Form.Item>
                                <Form.Item label="Clínica" className="inputHolder">
                                    <Input 
                                      value={'Acoxpa'}
                                      disabled={disabledField}
                                      onChange={(event)=>{
                                        setClinica(event.target.value);
                                      }}
                                    />
                                </Form.Item>
                            </div> */}

                            {/* <header className="fieldHeader">
                                Antecedentes Patológicos
                            </header>
                            <div className="formField patAgents">
                                <Form.Item label="Familiar con diabetes" className="inputHolder">
                                    <Input />
                                </Form.Item>
                                <Form.Item label="Familiar con hipertensión" className="inputHolder">
                                    <Input />
                                </Form.Item>
                            </div> */}
                            {/* <header className="fieldHeader" ref={estiloVida}>
                                Estilo de Vida
                            </header>
                            <div className="formField lifeStyle">
                                <Form.Item  label="Frecuencia con la que bebe" className="inputHolder">
                                  <Input 
                                    value={frecuenciaBeber}
                                    disabled={disabledField}
                                      onChange={(event)=>{
                                        setFrecuenciaBeber(event.target.value);
                                      }}
                                  />
                                </Form.Item>
                                <Form.Item label="Frecuencia con la que fumas" className="inputHolder">
                                  <Input 
                                    value={frecuenciaFumar}
                                    disabled={disabledField}
                                    onChange={(event)=>{
                                      setFrecuenciaFumar(event.target.value);
                                    }}
                                  />
                                </Form.Item>
                            </div>
                            <header className="fieldHeader" ref={alergias}>
                                Alergias
                            </header>
                            <div className="formField alergies">
                                <Form.Item label="Familiar con Diabetes"  className="inputHolder">
                                  <Input 
                                    value={'Hijos'}
                                    disabled={true}
                                    onChange={(event)=>{
                                      setFamiliarDiabetes(event.target.value);
                                    }}
                                  />
                                </Form.Item>
                            </div> */}
                        </Form>
                        {/* <header className="fieldHeader">
                            Antecedentes personales patológicos
                        </header>

                        <div className="formField personalPat">
                            <Form.Item label="Peso" className="inputHolder">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Talla" className="inputHolder">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="formField personalPat">
                            <Form.Item label="Tiene diabetes" className="inputHolder">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Tiene glaucoma" className="inputHolder">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="formField personalPat">
                            <Form.Item label="Db: Tratamiento" className="inputHolder">
                                <Input />
                            </Form.Item>
                            <Form.Item label="GPI: Tiempo de Evolución" className="inputHolder">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="formField personalPat">
                            <Form.Item label="Db: Tiempo de Evolución" className="inputHolder">
                                <Input />
                            </Form.Item>
                            <Form.Item label="GL: Tratamiento" className="inputHolder">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="formField personalPat">
                            <Form.Item label="Db: Tiempo de Evolución" className="inputHolder">
                                <Input />
                            </Form.Item>
                            <Form.Item label="GL: Tratamiento" className="inputHolder">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="formField personalPat">
                            <Form.Item label="Tiene hipertensión" className="inputHolder">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Cancer" className="inputHolder">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="formField personalPat">
                            <Form.Item label="Hp: Tiempo de Evolución" className="inputHolder">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Cr: Tiempo de Evolución" className="inputHolder">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="formField personalPat">
                            <Form.Item label="Cirugías no oftalmológicas" className="inputHolder">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Cr: Tratamiento" className="inputHolder">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="formField personalPat">
                            <Form.Item label="Otras enfermedades" className="inputHolder">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Internamientos" className="inputHolder">
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="formField personalPat">
                            <Form.Item label="Enfermedades Reumatológicas" className="inputHolder">
                                <Input />
                            </Form.Item>
                            <Form.Item label="Tratamiento de otras enfermedades" className="inputHolder">
                                <Input />
                            </Form.Item>
                        </div> */}
                        
                       

                        <div style={{
                          display:'flex',
                          justifyContent:'flex-end',
                          alignItems:"center"
                        }}>
                          <button
                            className="updateBtn"
                            onClick={(e)=>{
                              e.preventDefault();
                              updateAccount({
                                action:'updateAccount',
                                id_Firebase: "2209",
                                telefono: telefono,
                                genero: genero,
                                ocupacion: ocupacion
                              });
                              
                            }}
                          >
                           Actualizar  
                          </button>
                          
                        </div>
                        
                        

                    </section>
                </section>
            </div>
            </Layout>
        </Fragment>
    );
};


export default withRouter(UserInfo);