import React, { Fragment, useState, useEffect } from 'react';
import { Layout, Form, Input, Button } from "antd";
import Header from '../components/header';
import LoginHeader from '../components/LoginHeader';
import firebase from "firebase/app";
import app from "../firebaseConfig";
import { Auth } from "../context/AuthContext";

const SendPassword = () => {
  const { Content, Footer } = Layout;

  const sendPassword = async (values) => {
    const { mail } = values;
    await app
      .auth()
      .sendPasswordResetEmail(mail)
      .then(result => {
        alert('revisa tu email');
      })
      .catch(error => {
        console.error('error',error.message);
      });
  };

  return (
    <Fragment>
      <Header />
      <Layout className="container">
        <Content className="loginFormHolder">
          <LoginHeader />
          <Form className="login-form" onFinish={sendPassword}>
            <Form.Item>
              <h1 className="send-password-title">
                ¿Has olvidado la contraseña?
              </h1>
              <p className="send-password-paraf">
                Por favor introduce tu dirección de correo electrónico
                para recibir un enlace de restablecimiento de contraseña.
              </p>
            </Form.Item>
            <Form.Item
              label="Correo Electrónico"
              name="mail"
              rules={[{ required: true, message: 'Por favor ingresa tu usuario' }]}
              style={{marginBottom:'35px'}}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ marginRight: 10 }}
              >
                Recuperar Contraseña
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    </Fragment>
  );
};

export default SendPassword;