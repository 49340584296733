import React, {Fragment} from 'react';
import banner1 from '../images/banner1.png';

const Banner = () => {
  return (
    <Fragment>
      <section className="banner">
        <figure>
          <img src={banner1} alt="banner 1"/>
        </figure>
      </section>
    </Fragment>    
  );
};


export default Banner;