import React, { Fragment, useState, useEffect, useContext } from 'react';
import Header from '../components/header';
import { Layout } from "antd";
import AdminSidebar from '../components/AdminSidebar';
import RetinaSectionOne from '../components/retina/RetinaSectionOne';
import RetinaSectionTwo from '../components/retina/RetinaSectionTwo';
import RetinaSectionThree from '../components/retina/RetinaSectionThree';
import RetinaSectionFour from '../components/retina/RetinaSectionFour';
import RetinaSectionFive from '../components/retina/RetinaSectionFive';

const TestRetina = () => {
    const [visibleSectionOne, setVisibleSectionOne] = useState(true);
    const [visibleSectionTwo, setVisibleSectionTwo] = useState(false);
    const [visibleSectionThree, setVisibleSectionThree] = useState(false);
    const [visibleSectionFour, setVisibleSectionFour] = useState(false);
    const [visibleSectionFive, setVisibleSectionFive] = useState(false);
    const [answerOne, setAnswerOne] = useState(false);
    const [answerTwo, setAnswerTwo] = useState(false);
    const [answerThree, setAnswerThree] = useState(false);

    return (
        <Fragment>
            <Header />
            <Layout style={{ height: '100%' }}>
                <div className="retinaGrid">
                    <AdminSidebar />
                    <section className="retina-holder">
                        {visibleSectionOne === true ? (
                          <RetinaSectionOne 
                            handleNextSection={()=>{
                              setVisibleSectionOne(false);  
                              setVisibleSectionTwo(true); 
                              setVisibleSectionThree(false);
                              setVisibleSectionFour(false);
                              setVisibleSectionFive(false);
                            }}
                          />
                        ) : null}
                          
                        {visibleSectionTwo === true ? (
                          <RetinaSectionTwo 
                            handleNextSection={(value)=>{
                              setAnswerOne(value);  
                              setVisibleSectionOne(false);  
                              setVisibleSectionTwo(false); 
                              setVisibleSectionThree(true);
                              setVisibleSectionFour(false);
                              setVisibleSectionFive(false); 
                            }}
                          />
                        ) : null}

                        {visibleSectionThree === true ? (
                          <RetinaSectionThree 
                            handleNextSection={(value)=>{
                              setAnswerTwo(value);  
                              setVisibleSectionOne(false);  
                              setVisibleSectionTwo(false); 
                              setVisibleSectionThree(false);
                              setVisibleSectionFour(true);
                              setVisibleSectionFive(false);  
                            }}
                          />
                        ) : null} 
                        
                        {visibleSectionFour === true ? (
                          <RetinaSectionFour 
                            handleNextSection={(value)=>{
                              setAnswerThree(value);  
                              setVisibleSectionOne(false);  
                              setVisibleSectionTwo(false); 
                              setVisibleSectionThree(false);
                              setVisibleSectionFour(false);
                              setVisibleSectionFive(true); 
                            }}
                          />
                        ) : null} 

                        {visibleSectionFive === true ? (
                          <RetinaSectionFive />
                        ) : null}
                        

                        
                    </section>
                </div>
            </Layout>
        </Fragment>
    );
};


export default TestRetina;