import React, { Fragment, useContext, useState, useEffect } from "react";
import { PageHeader, Button } from "antd";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { Auth } from "../context/AuthContext";
import app from '../firebaseConfig';
import MobileMenu from './MobileMenu';
import generic_user from '../images/generic_user.png';

export default function Header({nombre,userId,history}) {
  const [openMenu, setOpenMenu] = useState(false);
  const [nombreUsuario, setNombreUsuario] = useState(null)
  const { usuario } = useContext(Auth);
  //const userIdent = JSON.parse(localStorage.getItem('id'));
  
  useEffect(() => {  
    usuario ? usuario.displayName ? setNombreUsuario(usuario.displayName) : setNombreUsuario(usuario.email) : setNombreUsuario(null)
  }, [history, usuario]);

  return (
    <Fragment>
      <PageHeader
        className="mainHeader"
      >
        <figure className="main-logo">
          <Link to={`/inicio`}>
            <img src="https://salauno.com.mx/wp-content/themes/understrap-child-master/img/logo-salauno.svg" />
          </Link>
        </figure>

        {usuario !== null ? (
          <Fragment>
            <div className="userIdent">
              <img src={generic_user} alt="generic_user" />
              <span className="userIdentName"> {nombreUsuario} </span>
            </div>

            
          </Fragment>
        ) : null}

        <button
          className={openMenu === true ? 'burgerButton change' : 'burgerButton'}
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </button>
      </PageHeader>
      <MobileMenu 
        openMenu={openMenu}
      />
    </Fragment>
  );
}