import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

const GlaucomaSectionThree = ({submitAnswer}) => {
    return (
        <Fragment>

            <div className="glaucomaSection">
                <div className="glaucoma-open-question">
                    <h1> El rango de presión que obtuve fue ___________ </h1>
                    <div className="glaucoma-options">
                        <button 
                          className="glaucoma-option"
                          onClick={()=>{
                            submitAnswer('10 a 14 mmHg');
                          }}
                        >
                          <span className="letter">
                            A
                          </span>
                          <span>
                            10 a 14 mmHg
                          </span>
                        </button>

                        <button 
                          className="glaucoma-option"
                          onClick={()=>{
                            submitAnswer('14 a 17 mmHg');
                          }}
                        >
                          <span className="letter">
                            B
                          </span>
                          <span>
                            14 a 17 mmHg
                          </span>
                        </button>

                        <button 
                          className="glaucoma-option"
                          onClick={()=>{
                            submitAnswer('18 a 20 mmHg');
                          }}
                        >
                           <span className="letter">
                                C
                           </span>
                           <span>
                                18 a 20 mmHg
                           </span>
                        </button>

                        <button 
                          className="glaucoma-option"
                          onClick={()=>{
                            submitAnswer('> 21 mmHg');
                          }}
                        >
                            <span className="letter">
                                D
                            </span>
                            <span>
                                > 21 mmHg
                           </span>
                        </button>
                    </div>

                </div>
            </div>
        </Fragment>
    );
};

GlaucomaSectionThree.propTypes = {};

export default GlaucomaSectionThree;