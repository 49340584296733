import React, { Fragment, useEffect, useState, useContext } from 'react';
import Header from '../components/header';
import { Layout, Modal } from "antd";
import AdminSidebar from '../components/AdminSidebar';
import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";
import bannerMisLentes from '../images/banner_mis_lentes.png';
import bannerMisLentesDos from '../images/banner_mis_lentes_2.png';
import lentesIcon from '../images/lentes_icon.png';
import API_CONSTANTS from '../utils/apiConstamts';
import { getUserInfo } from '../utils/api';

const axios = require('axios');
var moment = require('moment');

//const user_id = JSON.parse(localStorage.getItem('id'));
const GlassesView = ({ history }) => {
  const [userId, setUserId] = useState(null);
  const [glasses, setGlasses] = useState([]);
  const [folio, setFolio] = useState(null);
  const [fechaCreacion, setFechaCreacion] = useState(null);
  const [clinica, setClinica] = useState(null);
  const [showAgendar, setShowAgendar] = useState(false);
  const [selectedHorary, setSelectedHorary] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [servicesTypes, setServicesTypes] = useState([]);
  const [selectedClinica, setSelectedClinica] = useState(false);
  const [calendarId, setCalendarId] = useState(null);
  const [clinicasList, setClinicasList] = useState([]);
  const [dateTimes, setDateTimes] = useState({});
  const [selectedDay, setSelectedDay] = useState(null);
  const [servicioId, setServicioId] = useState(null);
  const [pacientDetail, setPacientDetail] = useState({});

  const { usuario } = useContext(Auth);
  const id_firebase = localStorage.getItem('idFirebase');

  const getGlasses = async () => {
    try {
      const resp = await axios.post(`${API_CONSTANTS.services}?action=login_lentes&id_Firebase=${id_firebase}`);
      const result = resp && resp.data && resp.data !== null ? resp.data : [];
      console.log('result', result.statusLentes);
      setGlasses(
        result &&
          result !== null &&
          result.statusLentes &&
          result.statusLentes !== null
          ? result.statusLentes : []
      );
      setFolio(result.statusLentes[0]['folio'] !== null ? result.statusLentes[0]['folio'] : null);
      setFechaCreacion(result.statusLentes[0]['fechaCreacion'] !== null ? result.statusLentes[0]['fechaCreacion'] : null);
      setClinica(result.statusLentes[0]['nombreClinica'] !== null ? result.statusLentes[0]['nombreClinica'] : null);
    } catch (err) {
      // Handle Error Here
      console.error('error al obtener la info de lentes del usuario', err);
    }
  }

  const daysOptions = (obj = {}) => {
    return Object.keys(obj)
      .map(property => <option value={property}> {moment(property).format('ll')} </option>);
  }

  /*Obtiene los servicios de los tipos de cita */

  const getIdCitas = async () => {
    const resp = await axios.post(`${API_CONSTANTS.services}?action=servicios_agenda`);
    setServicesTypes(resp !== null && resp.data !== undefined && resp.data !== null ? resp.data : []);
  }

  const getHorarios = async (serviceId = null, calendarId = null) => {
    try {
      const resp = await axios.post(`${API_CONSTANTS.services}?action=tiempos_sucursales&serviceId=${serviceId}&calendarId=${calendarId}`);
      const result = resp && resp !== null && resp.data && resp.data !== null && resp.data.dias &&
        resp.data.dias !== null ? resp.data.dias : {};
      setDateTimes(result);
    } catch (err) {
      console.error('error en peticion axios', err);
    }
  }

  const handleChangeDay = e => {
    setSelectedDay(e.target.value);
  }

  const handleChangeHorary = e => {
    const day = dateTimes[`${selectedDay}`];
    setSelectedHorary(e.target.value);
    console.log('day',day);
    const filteredDay = day.filter(item=>{
      return item.completo === e.target.value;
    });
    const newCalendarId = filteredDay[0].calendarId;
    console.log('newServiceId',newCalendarId);
  }

  const horaryOptions = () => {
    if (!selectedDay || !dateTimes[selectedDay]) {
      return null;
    }
    return (dateTimes[selectedDay] ?? [])
      .map(horary => <option value={horary.completo}> {horary.corto} </option>);
  }

  const handleCreateDate = async () => {
    let fullName = pacientDetail['nombre'].split(' ');
    try {
      const resp = await axios.post(`${API_CONSTANTS.services}?action=agendar_login_presencial&nombre=${fullName[0]}&apellidos=${fullName[1]}${fullName[2]}&datetime=${selectedHorary}&telefono=${pacientDetail.telefono}&email=${pacientDetail.correo}&serviceId=${servicioId}&calendarId=${calendarId}&expediente=${pacientDetail.expediente}`);
      console.log('tu cita ha sido agendada');
      setShowAgendar(false);
    } catch (err) {
      console.error('error en peticion axios', err);
    }
  }

  {/* Obtiene los datos de las clinicas */ }

  const getClinicas = async () => {
    try {
      const resp = await axios.post(`${API_CONSTANTS.services}?action=login_clinicas`);
      const result = resp && resp !== null && resp.data && resp.data !== null ? resp.data : [];
      console.log('clinicas', result);
      setClinicasList(result);
    } catch (err) {
      console.error('error en peticion axios', err);
    }
  }

  const pacientInfo = async () => {
    const detail = await getUserInfo();
    setPacientDetail(detail);
  }

  useEffect(() => {
    if (usuario === null) {
      history.push(`/login`);
    }
    setUserId(usuario && usuario !== null && usuario.id && usuario.id !== null ? usuario.uid : null);
  }, [usuario, history]);


  useEffect(() => {
    getGlasses();
    getClinicas();
    getIdCitas();
    pacientInfo();
  }, []);

  return (
    <Fragment>
      <Layout>
        <Header />
        <div className="glassesGrid">
          <AdminSidebar />
          <section className="main-glasses">
            <div className="banner">
              <img src={bannerMisLentes} alt="banner principal" />
            </div>

            {glasses.length <= 0 ? (
              <div className="glasses-bannerTwo">
                <img src={bannerMisLentesDos} alt="banner principal" />
                <button
                  className="bannerTwoBtn"
                  onClick={() => {
                    setShowAgendar(true);
                  }}
                >
                  Hacer una cita
                            </button>
              </div>
            ) : (
              <div className="glassesTable">
                <header className="visual-header">
                  Mis Lentes
                           </header>
                <div className="treatmentTable-holder">
                  <div className="glasses-row-one">
                    <div className="info-side">
                      <label className="info-label">
                        Folio
                                        </label>
                      <span> {folio} </span>
                    </div>
                    <div className="info-side">
                      <label className="info-label">
                        Fecha de Pedido
                                      </label>
                      <span> {moment(fechaCreacion).format('l')} </span>
                    </div>
                  </div>
                  <div className="glassesInfo-table">
                    <article className="glasses-model">
                      <figure className="glasses-photo">
                        <img src={lentesIcon} alt="lentes-icon" />
                      </figure>
                      <div className="model-info">
                        <h4> Módelo </h4>
                        <p>
                          Cyxus Lentes Luz Rojo Pasta Hombres con graduación
                                          </p>
                      </div>
                    </article>

                    <article className="glasses-info">
                      <div className="glasses_pricetag">
                        <h4>Precio </h4>
                        <p> $1,250 </p>
                      </div>
                      <div className="glasses-sucursal">
                        <h4> Sucursal de entrega </h4>
                        <p>
                          {clinica}
                        </p>
                      </div>
                      <div className="glassesStatus">
                        <h4> Status </h4>
                        <span> Por entregar </span>
                      </div>
                      <div className="glasses-estimated">
                        <p>
                          Tiempo de entrega:
                                            <br /> 8 a 10 días hábiles
                                          </p>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
        <Modal
          title="Agendar una cita"
          visible={showAgendar}
          onOk={() => { setShowAgendar(false) }}
          onCancel={() => {
            setShowAgendar(false)
            setSelectedDay(null);
            setSelectedHorary(null);
          }}
          width={548}
          className="modalEdit"
        >
          <article className="modal-dates-holder">
            <h3 className="modalDatesTitle"> Agenda tu cita </h3>
            <p style={{ textAlign: 'center' }}> Tu doctor de los ojos te está esperando. </p>
            <div className="dateSchedule">

              <article className="schedule-item">
                <h4> ¿Qué tipo de cita necesitas? </h4>
                <select
                  className="schedule-select"
                  onChange={e => {
                    setSelectedService(e.target.value);
                  }}
                  value={selectedService}
                >
                  <option value=""> Selecciona tipo de cita</option>
                  {servicesTypes.map(service => {
                    return <option value={service.serviceId}>
                      {service.nombre}
                    </option>
                  })}
                </select>
              </article>

              <article className="schedule-item">
                <h4> ¿A qué clínica quieres asistir? </h4>
                <select className="schedule-select"
                  onChange={e => {
                    setSelectedClinica(e.target.value);
                    const filteredClinic = clinicasList.filter(clinic => {
                      return clinic.nombre === e.target.value;
                    });
                    getHorarios(
                      filteredClinic[0]['consulta']['servicioId'],
                      filteredClinic[0]['consulta']['calendarId']
                    );
                    setServicioId(filteredClinic[0]['consulta']['servicioId']);
                    setCalendarId(filteredClinic[0]['consulta']['calendarId']);
                  }}
                  value={selectedClinica}
                >
                  <option value=""> Elige clínica </option>
                  {clinicasList.map(clinica => {
                    return <option value={clinica.nombre}>
                      {clinica.nombre}
                    </option>
                  })}
                </select>
              </article>

              <article className="schedule-item">
                <h4> Selecciona un nuevo día para tu cita  </h4>
                {daysOptions && daysOptions !== null ? (
                  <select className="schedule-select" onChange={handleChangeDay} value={selectedDay}>
                    <option value=""> Selecciona dia </option>
                    {daysOptions(dateTimes)}
                  </select>
                ) : null}

              </article>
              <article className="schedule-item">
                <h4> Selecciona un nuevo horario para tu cita </h4>
                <select className="schedule-select" onChange={handleChangeHorary} value={selectedHorary}>
                  <option value=""> Selecciona horario </option>
                  {horaryOptions()}
                </select>
              </article>


              <div className="date-finish-buttons">
                <button
                  className="change-date-button"
                  onClick={() => {
                    handleCreateDate();
                  }}
                >
                  Agendar Cita
                      </button>
              </div>
            </div>
          </article>
        </Modal>
      </Layout>
    </Fragment>
  );
};



export default withRouter(GlassesView);