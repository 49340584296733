import React, { Fragment, useState, useContext } from "react";
import { Form, Input, Button, Checkbox, DatePicker, Space, ConfigProvider, Spin } from "antd";
import { withRouter } from "react-router";
import { Modal } from 'antd';
import 'antd/dist/antd.css';
import { setUserInfo } from '../actions';
import { connect } from 'react-redux';
import 'moment/locale/es-mx';
import locale from 'antd/lib/locale/es_ES';
import { createFirebaseUser, loginWithGoogle, loginWithFacebook } from "../utils/fire";
import ExpidentQuestion from "../components/signup/ExpidentNumber";
import ExpidentNumber from "../components/signup/ExpidentNumber";
import { existLead, searchAccountByExpedient, updateAccount } from "../utils/api";

var moment = require('moment');

const formInitialState = {
  name: '',
  lastName: '',
  email: '',
  telephone: '',
  birthday: null,
  password: '',
  confirmPassword: ''
}

const stepEnum = {
  EXPEDIENT_QUESTION: 'EXPEDIENT_QUESTION',
  EXPEDIENT_NUMBER: 'EXPEDIENT_NUMBER',
  CALL_CENTER: 'CALL_CENTER'
}

const Signup = ({ setsignup, info_usuario, setUserInfo, history }) => {

  const [form, setForm] = useState(formInitialState);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [step, setStep] = useState(stepEnum.EXPEDIENT_QUESTION);

  const handleOnChangeField = ({ target }) => setForm({ ...form, [target.name] : target.value });

  const handleOnFinish = async () => {
    setIsOpenModal(true);
  }

  const handleOnLoginGoogle = async () => {
    const user = await loginWithGoogle();
    console.log(user);
  }

  const handleOnLoginFacebook = async () => {
    const user = await loginWithFacebook();
    console.log(user);
  }

  const handleOnOkExpedientQuestion = () => {
    console.log('entramos a handleOnOkExpedientQuestion');
    setStep(stepEnum.EXPEDIENT_NUMBER)
  };

  const handleOnCancelExpedientQuestion = async () => {
    setIsLoading(true);
    const { email, telephone, password } = form;
    const lead = await existLead({ email, telephone });
    console.log('entrando a funcion handleOnOkExpedientQuestion');
    if(lead == null) {
      const user = await createFirebaseUser({ email, password });
      console.log(user);
    }
  }

  const handleOnCloseModal = () => {
    setIsOpenModal(false);
    setStep(stepEnum.EXPEDIENT_QUESTION);
  }
 
  const handleOnOkExpedientNumber = async (expedient) => {
    console.log('entrando a funcion handleOnOkExpedientNumber');
    console.log('expediente recibido',expedient);
    setIsLoading(true);
    const account = await searchAccountByExpedient({ expedient });
    if(account) {
      const { email, password } = form;

      const firebaseUser = await createFirebaseUser({ email, password });
      await updateAccount({ newUid: firebaseUser.uid, accountId: account.id });
      console.log('ccount.id',account.id);
      localStorage.setItem('id',account.id);
      localStorage.setItem('idFirebase',firebaseUser.uid);
      console.log('cuenta actualizada', firebaseUser, account);
    } else {
      alert('Call center');
    }
  }


  return (
    <Fragment>
      <Form className="login-form" onFinish={handleOnFinish}>
        
        <Form.Item>
          <h1 style={styles.h1}>
            Crea tu cuenta en Sala Uno
          </h1>
        </Form.Item>

        <Form.Item className="social-buttons" style={styles.social}>
          <button className="social-btn" type="button" onClick={handleOnLoginGoogle}> Google </button>
          <button className="social-btn" type="button" onClick={handleOnLoginFacebook}> Facebook </button>
        </Form.Item>

        <div className="lines">
          <span className="lineItem"></span> O <span className="lineItem"></span>
        </div>

        <div className="registerRow">
          <Form.Item label="Nombre" name="Nombre" rules={[{ required:true, message: 'Ingrese el nombre', }]} className="create-input-holder" style={styles.input} >
            <Input
              value={form.name}
              onChange={handleOnChangeField}
              name="name"
              placeholder="Nombre"
              className="create-input half"
            />
          </Form.Item>

          <Form.Item label="Apellido(s)" name="Apellidos" className="create-input-holder" style={styles.input} rules={[{ required:true, message: 'Ingrese el apellido' }]}>
            <Input
              value={form.lastName}
              onChange={handleOnChangeField}
              name="lastName"
              placeholder="Apellido(s)"
              className="create-input half"
            />
          </Form.Item>
        </div>


        <div className="registerRow">
          <Form.Item label="Email" name="Email" style={styles.inputFull} rules={[{ required: true, message: 'El correo es requerido' }, { type: 'email', message: 'El email debe de tener un formato valido' }]}>
            <Input
              value={form.email}
              onChange={handleOnChangeField}
              name="email"
              type="email"
              placeholder="Correo Electrónico"
              className="create-input long"
            />
          </Form.Item>
        </div>

        <div className="registerRow">
          <Form.Item label="Teléfono" name="Telefono" style={styles.input} rules={[{ required:true, message: 'El telefono es requerido' }]}>
            <Input
              value={form.telephone}
              onChange={handleOnChangeField}
              name="telephone"
              className="create-input half"
            />
          </Form.Item>

          <Form.Item label="Fecha de Nacimiento" name="birthday" style={styles.input}>
            <ConfigProvider locale={locale}>
              <DatePicker 
                format={'L'} 
                placeholder="" 
                style={styles.datePicker} 
                defaultValue={moment()} 
                onChange={(date, dateString) => {
                  handleOnChangeField({ target: { value: dateString, name: 'birthday' }});
                }}
                disabledDate={current => {
                  return current && current > moment().add(8, "months");
                }} 
              />
            </ConfigProvider>
          </Form.Item>
        </div>

        <div className="registerRow">
          <Form.Item
            name="password"
            label="Contraseña"
            className="create-input-holder"
            style={styles.inputFull}
            rules={[{ required:true, message: 'La contraseña es requerida' }]}
          >
            <Input.Password
              value={form.password}
              onChange={handleOnChangeField}
              name="password"
              className="create-input long"
            />
          </Form.Item>
        </div>


        <div className="registerRow">
          <Form.Item
            label="Confirmar Contraseña"
            name="confirmPassword"
            className="create-input-holder"
            style={styles.inputFull}
            rules={[
              { required:true, message: 'Vueleva a ingresar la contraseña' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Las contraseñas no coinciden'));
                },
              })
            ]}
          >
            <Input.Password
              className="create-input long"
              value={form.confirmPassword}
              onChange={handleOnChangeField}
            />
          </Form.Item>
        </div>


        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button">
          Registrate
        </Button>


      </Form>



      <Modal title="Datos" visible={isOpenModal} footer={null} onCancel={handleOnCloseModal}>
       { isLoading ? <Spin /> :  null }

       { !isLoading && step ===  stepEnum.EXPEDIENT_QUESTION 
        ? <ExpidentQuestion onOk={handleOnOkExpedientNumber} onCancel={handleOnCancelExpedientQuestion} /> : null }

       { !isLoading && step == stepEnum.EXPEDIENT_NUMBER 
        ? <ExpidentNumber onOk={handleOnOkExpedientNumber} onCancel={handleOnCloseModal} /> : null }
      </Modal>

    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    info_usuario: state.info_usuario
  }
}

const mapDispatchToProps = {
  setUserInfo,
}

const styles = {
  h1: {
    color: '#171725',
    margin: '0 0 25px 0',
    fontWeight: 'bold',
    fontSize: '17px',
    fontFamily: ['Open Sans', 'sans-serif']
  },
  social: { 
    display: 'flex', 
    justifyContent: 
    'space-between', 
    alignItems: 'center' 
  },
  input: { 
    width: '45%' 
  },
  inputFull: {
    width: '100%'
  },
  datePicker: { 
    marginBottom: '0', 
    border: 'none'
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signup));