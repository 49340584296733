import React, { Fragment, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Header from '../components/header';
import { Layout } from "antd";
import AdminSidebar from '../components/AdminSidebar';
import GlaucomaSectionOne from '../components/glaucoma/GlaucomaSectionOne';
import GlaucomaSectionTwo from '../components/glaucoma/GlaucomaSectionTwo';
import GlaucomaSectionThree from '../components/glaucoma/GlaucomaSectionThree';
import GlaucomaSectionFour from '../components/glaucoma/GlaucomaSectionFour';
import GlaucomaSectionFive from '../components/glaucoma/GlaucomaSectionFive';
import GlaucomaSectionSix from '../components/glaucoma/GlaucomaSectionSix';
import GlaucomaSectionSeven from '../components/glaucoma/GlaucomaSectionSeven';
import GlaucomaSectionEight from '../components/glaucoma/GlaucomaSectionEight';
import GlaucomaSectionNine from '../components/glaucoma/GlaucomaSectionNine';
import GlaucomaSectionTen from '../components/glaucoma/GlaucomaSectionTen';
import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";



const TestGlaucoma = ({history}) => {
    const [visibleSectionOne, setVisibleSectionOne] = useState(true);
    const [visibleSectionTwo, setVisibleSectionTwo] = useState(false);
    const [visibleSectionThree, setVisibleSectionThree] = useState(false);
    const [visibleSectionFour, setVisibleSectionFour] = useState(false);

    const [visibleSectionFive, setVisibleSectionFive] = useState(false);
    const [visibleSectionSix, setVisibleSectionSix] = useState(false);
    const [visibleSectionSeven, setVisibleSectionSeven] = useState(false);
    const [visibleSectionEight, setVisibleSectionEight] = useState(false);
    const [visibleSectionNine, setVisibleSectionNine] = useState(false);
    const [visibleSectionTen, setVisibleSectionTen] = useState(false);

    const [visibleEndOne, setVisibleEndOne] = useState(false);
    const [visibleEndTwo, setVisibleEndTwo] = useState(false);

    const [hasGlaucoma, setHasGlaucoma] = useState(false);
    const [intraOcularPress, setIntraOcularPress] = useState(false);
    const [pressureRank, setPressureRank] = useState(null);
    const [hadDiabetes, setHadDiabetes] = useState(false);
    const [hadMigraña, setHadMigraña] = useState(false);
    const [hasTrauma, setHasTrauma] = useState(false);
    const [hasOcularPain, setHasOcularPain] = useState(false)
    const [colorios, setColorios] = useState(false);
    const [ageRank, setAgeRank] = useState(null);

    const { usuario } = useContext(Auth);
    
    useEffect(() => {
        if (usuario === null) {
          history.push(`/login`);
          console.log('usuario no logeado');
        }
    }, [usuario,history]);

    return (
        <Fragment>
            <Header />
            <Layout style={{ height: '100%' }}>
                <div className="tratments-grid glaucoma">
                    <AdminSidebar />
                    <section className="treatments-holder">
                        <header className="glaucoma-header">
                            Test de Glaucoma
                        </header>
                        {
                          visibleSectionOne === true ? (
                            <GlaucomaSectionOne 
                               submitAnswer={value=>{
                                setHasGlaucoma(value);
                                setVisibleSectionOne(false);
                                setVisibleSectionTwo(true);
                                setVisibleSectionThree(false);
                                setVisibleSectionFour(false);
                                setVisibleSectionFive(false);
                                setVisibleSectionSix(false);
                                setVisibleSectionSeven(false);
                                setVisibleSectionEight(false);
                                setVisibleSectionNine(false);
                                setVisibleSectionTen(false);
                               }}
                            />
                          ):null
                        }

                        {
                          visibleSectionTwo === true ? (
                            <GlaucomaSectionTwo 
                             submitAnswer={value=>{
                              setIntraOcularPress(value);
                              setVisibleSectionOne(false);
                              setVisibleSectionTwo(false);
                              setVisibleSectionThree(true);
                              setVisibleSectionFour(false);
                              setVisibleSectionFive(false);
                              setVisibleSectionSix(false);
                              setVisibleSectionSeven(false);
                              setVisibleSectionEight(false);
                              setVisibleSectionNine(false);
                              setVisibleSectionTen(false);
                             }}
                            />
                          ):null
                        }
                        
                        {
                          visibleSectionThree === true ? (
                            <GlaucomaSectionThree 
                             submitAnswer={value=>{
                              setPressureRank(value);
                              setVisibleSectionOne(false);
                              setVisibleSectionTwo(false);
                              setVisibleSectionThree(false);
                              setVisibleSectionFour(true);
                              setVisibleSectionFive(false);
                              setVisibleSectionSix(false);
                              setVisibleSectionSeven(false);
                              setVisibleSectionEight(false);
                              setVisibleSectionNine(false);
                              setVisibleSectionTen(false);
                             }}
                            />
                          ):null
                        }

                        {
                          visibleSectionFour === true ? (
                            <GlaucomaSectionFour 
                             submitAnswer={value=>{
                              setHadDiabetes(value);
                              setVisibleSectionOne(false);
                              setVisibleSectionTwo(false);
                              setVisibleSectionThree(false);
                              setVisibleSectionFour(false);
                              setVisibleSectionFive(true);
                              setVisibleSectionSix(false);
                              setVisibleSectionSeven(false);
                              setVisibleSectionEight(false);
                              setVisibleSectionNine(false);
                              setVisibleSectionTen(false);
                             }}
                            />
                          ):null
                        }
                        
                        {
                          visibleSectionFive === true ? (
                            <GlaucomaSectionFive 
                             submitAnswer={value=>{
                              setHadMigraña(value);
                              setVisibleSectionOne(false);
                              setVisibleSectionTwo(false);
                              setVisibleSectionThree(false);
                              setVisibleSectionFour(false);
                              setVisibleSectionFive(false);
                              setVisibleSectionSix(true);
                              setVisibleSectionSeven(false);
                              setVisibleSectionEight(false);
                              setVisibleSectionNine(false);
                              setVisibleSectionTen(false);
                             }}
                            />
                          ):null
                        }
                        
                        {
                          visibleSectionSix === true ? (
                            <GlaucomaSectionSix 
                            submitAnswer={value=>{
                              setHasTrauma(value);
                              setVisibleSectionOne(false);
                              setVisibleSectionTwo(false);
                              setVisibleSectionThree(false);
                              setVisibleSectionFour(false);
                              setVisibleSectionFive(false);
                              setVisibleSectionSix(false);
                              setVisibleSectionSeven(true);
                              setVisibleSectionEight(false);
                              setVisibleSectionNine(false);
                              setVisibleSectionTen(false);
                             }}
                            />
                          ):null
                        }

                        {
                          visibleSectionSeven === true ? (
                            <GlaucomaSectionSeven 
                            submitAnswer={value=>{
                              setHasOcularPain(value);
                              setVisibleSectionOne(false);
                              setVisibleSectionTwo(false);
                              setVisibleSectionThree(false);
                              setVisibleSectionFour(false);
                              setVisibleSectionFive(false);
                              setVisibleSectionSix(false);
                              setVisibleSectionSeven(false);
                              setVisibleSectionEight(true);
                              setVisibleSectionNine(false);
                              setVisibleSectionTen(false);
                             }}
                            />
                          ):null
                        }
                        
                        {
                          visibleSectionEight === true ? (
                            <GlaucomaSectionEight 
                            submitAnswer={value=>{
                              setColorios(value);
                              setVisibleSectionOne(false);
                              setVisibleSectionTwo(false);
                              setVisibleSectionThree(false);
                              setVisibleSectionFour(false);
                              setVisibleSectionFive(false);
                              setVisibleSectionSix(false);
                              setVisibleSectionSeven(false);
                              setVisibleSectionEight(false);
                              setVisibleSectionNine(true);
                              setVisibleSectionTen(false);
                             }}
                            />
                          ):null
                        }
                        
                        {
                          visibleSectionNine === true ? (
                            <GlaucomaSectionNine 
                            submitAnswer={value=>{
                              setAgeRank(value);
                              setVisibleSectionOne(false);
                              setVisibleSectionTwo(false);
                              setVisibleSectionThree(false);
                              setVisibleSectionFour(false);
                              setVisibleSectionFive(false);
                              setVisibleSectionSix(false);
                              setVisibleSectionSeven(false);
                              setVisibleSectionEight(false);
                              setVisibleSectionNine(false);
                              setVisibleSectionTen(true);
                             }}
                            />
                          ):null
                        }

                        {
                          visibleSectionTen === true ? (
                            <GlaucomaSectionTen 
                              takeTest = {hasGlaucoma === true ||
                                intraOcularPress === true || 
                                hadDiabetes === true ||
                                hadMigraña === true ||
                                hasTrauma === true ||
                                hasOcularPain === true ||
                                colorios === true ? true : false}      
                            />
                          ):null
                        }
                        
                        
                    </section>
                </div>
            </Layout>
        </Fragment>
    );
};

TestGlaucoma.propTypes = {};

export default withRouter(TestGlaucoma);