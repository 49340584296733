import React, { Fragment, useContext, useEffect, use } from 'react';
import Header from '../components/header';
import { Layout } from "antd";
import AdminSidebar from '../components/AdminSidebar';
import TreatmentTable from '../components/TreatmentTable';
import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";

const TreatmentsView = ({history}) => {
    const tabledata = [
        {
            folio: 'PT-502210',
            tratamiento: "Cx Catarata",
            ojo: 'Izquierdo',
            estado: "Realizado",
            medico: 'José Antonio Soto Muñoz',
            fecha: "02/05/21",
        },
        {
            folio: 'PT-502210',
            tratamiento: "Cx Catarata",
            ojo: 'Izquierdo',
            estado: "Realizado",
            medico: 'José Antonio Soto Muñoz',
            fecha: "02/05/21",
        },
        {
            folio: 'PT-502210',
            tratamiento: "Cx Catarata",
            ojo: 'Izquierdo',
            estado: "Realizado",
            medico: 'José Antonio Soto Muñoz',
            fecha: "02/05/21",
        },
        {
            folio: 'PT-502210',
            tratamiento: "Cx Catarata",
            ojo: 'Izquierdo',
            estado: "Realizado",
            medico: 'José Antonio Soto Muñoz',
            fecha: "02/05/21",
        },
        {
            folio: 'PT-502210',
            tratamiento: "Cx Catarata",
            ojo: 'Izquierdo',
            estado: "Realizado",
            medico: 'José Antonio Soto Muñoz',
            fecha: "02/05/21",
        },
        {
            folio: 'PT-502210',
            tratamiento: "Cx Catarata",
            ojo: 'Izquierdo',
            estado: "Realizado",
            medico: 'José Antonio Soto Muñoz',
            fecha: "02/05/21",
        },
    ];

    const columns = [
        { title: "Folio", field: "folio", sorter: "string" },
        { title: "Tratamiento", field: "tratamiento", sorter: "string" },
        { title: "Ojo", field: "ojo", sorter: "string", cellClick: function (e, cell) { console.log("cell click", cell) }, },
        { title: "Estado", field: "estado", formatter: "string" },
        { title: "Medico", field: "medico", sorter: "string" },
        { title: "Fecha", field: "fecha", sorter: "date" },
    ];
    
    const { usuario } = useContext(Auth);


    useEffect(() => {
      if (usuario === null) {
        history.push(`/login`);
        console.log('usuario no logeado');
      }
    }, [usuario,history]);


    return (
        <Fragment>
            <Header />
            <Layout style={{ height: '100%' }}>
                <div className="tratments-grid">
                    <AdminSidebar />
                    <section className="treatments-holder">
                        <div className="treat-table-container">
                            <TreatmentTable
                                tabledata={tabledata}
                                columns={columns}
                            />
                        </div>

                    </section>
                </div>
            </Layout>
        </Fragment>
    );
};

export default withRouter(TreatmentsView);