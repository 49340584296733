import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

const GlaucomaSectionEight = ({submitAnswer}) => {
    return (
        <Fragment>
            <div className="glaucomaSection">
                <div className="glaucoma-question">
                    <h1> Has utilizado gotas colirios con esteroide por tiempo
prolongado sin vigilancia oftalmológica? </h1>
                    <div className="glaucoma-buttons">
                        <button
                          onClick={()=>{
                            submitAnswer(true);  
                          }}
                        > Si </button>
                        <button
                          onClick={()=>{
                            submitAnswer(false);  
                          }}
                        > No</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

GlaucomaSectionEight.propTypes = {};
export default GlaucomaSectionEight;