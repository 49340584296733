import React, { Fragment, useEffect, useContext} from 'react';
import { Layout } from 'antd';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Header from '../components/header';
import AdminSidebar from '../components/AdminSidebar';
import BannerControlVisual from '../images/banner_control_visual.png';
import portadaGlaucoma from '../images/glaucoma_portada.png';
import portadaFuncionRetina from '../images/portada_funcion_retina.png';
import { NavLink } from 'react-router-dom';
import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";



const VisualControl = ({history}) => {
    const { usuario } = useContext(Auth);
    useEffect(() => {
        if (usuario === null) {
          history.push(`/login`);
          console.log('usuario no logeado');
        }
    }, [usuario,history]);
    return (
        <Fragment>
            <Header />
            <Layout className="container">
                <div className="tratments-grid">
                    <AdminSidebar />
                    <section className="treatments-holder">
                        <header className="banner">
                            <img src={BannerControlVisual} />
                        </header>
                        <div className="visual-control-slider">
                            <Splide
                               options={{
                                perPage: 2,
                                gap: '25px',
                                breakpoints: {
                                    '768': {
                                        perPage: 2,
                                        gap: '1em',
                                    },
                                    '767': {
                                        perPage: 1,
                                        gap: '1em',
                                    },
                                }
                               }}
                               
                            >
                                <SplideSlide>
                                    <article className="visual-control-item">
                                        <figure className="visual-control-figure">
                                            <img src={portadaGlaucoma} alt="Image 1" />
                                        </figure>
                                        <div className="visual-control-text">
                                            <h3> Test de Glaucoma </h3>
                                            <p> A continuación le presentamos un cuestionario que nos permitirá conocer su historia clínica e identificar factores de riesgo que nos ayuden a tamizar si usted tiene algún riesgo de padecer glaucoma. </p>
                                            <p>
                                                *Este cuestionario no sustituye el diagnóstico médico, solo nos permite detectar pacientes con “sospecha de glaucoma” para canalizar con el especialista.
                                            </p>
                                        </div>
                                        <NavLink to="/test-glaucoma" className="visual-control-button">
                                            Iniciar Test
                                        </NavLink>
                                    </article>
                                </SplideSlide>
                                <SplideSlide>
                                    <article className="visual-control-item">
                                        <figure className="visual-control-figure">
                                            <img src={portadaFuncionRetina} alt="Image 2" />
                                        </figure>
                                        <div className="visual-control-text">
                                            <h3> Prueba de función de la retina </h3>
                                            <p> La denominada prueba Amsler puede ayudar a descubrir enfermedades peligrosas de la mácula. Te invitamos a que hagas esta sencilla prueba </p>
                                        </div>
                                        <NavLink to="/test-retina" className="visual-control-button">
                                            Iniciar Test
                                        </NavLink>
                                    </article>
                                </SplideSlide>
                            </Splide>
                        </div>
                    </section>
                </div>
            </Layout>
        </Fragment>
    );
};



export default withRouter(VisualControl);