import firebase from "firebase";
import "firebase/auth";
//import dataConfig from './dataConfig'



const app = firebase.initializeApp({
    apiKey: "AIzaSyBNJubxl7vS0ovW81mMUhnPE6NR995Ifjk",
    authDomain: "salaunoapp.firebaseapp.com",
    databaseURL: "https://salaunoapp.firebaseio.com",
    projectId: "salaunoapp",
    storageBucket: "salaunoapp.appspot.com",
    messagingSenderId: "446581281300",
    appId: "1:446581281300:web:5cb6af8d94b204ebb8cd01"
});

/* const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const phoneAuthProvider = new firebase.auth.PhoneAuthProvider(); */


export default app;