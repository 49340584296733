import axios from 'axios';


export const existLead = async ({ email, telephone }) => {
    const searchByEmail = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?action=account_by_email&correo=${email}`)
    if(searchByEmail?.data?.pacientes && searchByEmail?.data?.pacientes[0]) {
        return searchByEmail?.data?.pacientes[0];
    }

    const searchByTelephone = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?action=account_by_celular&celular=${telephone}`);
    if(searchByTelephone?.data?.pacientes && searchByTelephone?.data?.pacientes[0]) {
        return searchByTelephone?.data?.pacientes[0];
    }
    return null;
}

export const searchAccountByExpedient = async ({ expedient }) => {
    const response = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?action=search_expediente&expediente=${expedient}`);
    if(response?.data?.pacientes && response?.data?.pacientes[0]) {
        return response?.data?.pacientes[0];
    }
    return null;
}

export const updateAccount = async ({ newUid, accountId }) => {
    const response = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?action=login_update_id_account&idLead=${accountId}&idFirebase=${newUid}`);
    return response?.data;
}


export const getUserInfo = async () => {
  const response = await axios.post(`https://salauno.testapps.mx/wp-admin/admin-ajax.php?action=login_info&id_Firebase=${localStorage.getItem('idFirebase')}`);
  if(response?.data?.pacientes && response?.data?.pacientes[0]) {
    console.log('pacientes',response.data.pacientes);  
    return response?.data?.pacientes[0];
  }
  return null;
} 
