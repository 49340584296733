import React, { Fragment, useState, useEffect, useContext } from 'react';
import firebase from "firebase/app";
import app from "../firebaseConfig";
import { Layout } from "antd";
import { Form, Input, Button, Checkbox} from "antd";
import { Auth } from "../context/AuthContext";
import Signup from "./signup";
import { withRouter } from "react-router";
import {Link} from 'react-router-dom';
import Errores from '../components/Errores';
import Header from '../components/header';
import LoginHeader from '../components/LoginHeader';
import API_CONSTANTS from '../utils/apiConstamts';

const Login = ({ history }) => {
  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
  const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

  const { Content, Footer } = Layout;
  const [signup, setsignup] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const { usuario } = useContext(Auth);
  const [error, seterror] = useState('');

  const axios = require('axios');

  useEffect(() => {
    if (usuario) {
      history.push(`/inicio`);
    }
    console.log('usuario',usuario);
  }, [history, usuario]);


  /* buscar usuario por firebaseID */

  const GetUserIdFirebase = async (idFirebase) => {
    console.log('idFirebase GetUserIdFirebase',idFirebase);
    try {
        const resp = await axios.post(`${API_CONSTANTS.services}?action=login_info&id_Firebase=${idFirebase}`);
        console.log('info de paciente',resp.data);
        console.log('datos de consulta',`${API_CONSTANTS.services}?action=login_info&id_Firebase=${idFirebase}`);
        const result = resp !== undefined && resp !==null && resp.data !== undefined && resp.data !== null && resp.data.pacientes !== null && resp.data.pacientes !== undefined ? resp.data.pacientes[0] : {};
        console.log('reultado de GetUserIdFirebase', result);
        return resp.data.pacientes[0];
    } catch (err) {
        // Handle Error Here
        console.error('error al obtener la info del usuario',err);
        return {}
    }
  }
  
  /* Logear por mail */
  const correoClave = async (values) => {
    const { usuario, clave } = values;
    
    await app
      .auth()
      .signInWithEmailAndPassword(usuario, clave)
      .then(async result =>  {
        const userIdFirebase = await GetUserIdFirebase(result.user.uid);
        console.log('userIdFirebase',userIdFirebase);
        saveLoginIds(result.user.uid, userIdFirebase.id);
        console.log('uid',result.user.uid);
        const user_id = localStorage.getItem('id');
        console.log('user_id',user_id);
        
        /* const usersRef = firebase.firestore().collection('users').doc(result.user.uid)
        usersRef.get().then((doc) => {
            if (doc.exists) {
                console.log('id de firebase', doc.data());
                const { id, idFirebase } = doc.data();
                console.log('Datos desde el Login', {id, idFirebase });
                saveLoginIds(result.user.uid, JSON.stringify(id), result.user.uid);
                history.push(`/inicio`);
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        }); */
      })
      .catch(error => {
        console.error('error',error.message);
        seterror(error.message)
      });
  };
  

  /* saveLoginIds */
  const saveLoginIds = (newUid, id, idFirebase) => {
    localStorage.setItem('id',id);
    localStorage.setItem('idFirebase', newUid);
    //localStorage.setItem('oldIdFirebase', idFirebase); 
  }

  /* registro con google o facebook  */
  const socialLogin = async (provider) => {
    await app
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        const usersRef = firebase.firestore().collection('users').doc(result.user.uid);
        usersRef.get().then((doc) => {
          if (doc.exists) {
            const { id, idFirebase } = doc.data();
            localStorage.setItem('id', id);
            localStorage.setItem('idFirebase', idFirebase);
            localStorage.setItem('providerId', provider.providerId);
          } else {
              console.log("No such document!");
          }
        }).catch((error) => {
          console.log("Error getting document:", error);
        });
      })
      .catch(error => {
        console.error('error',error.message);
        seterror(error.message)
      });
  }
  
  /* validar correo electronico */
  const validarUsuario = (value) => {
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (emailRegex.test(value)) {
      setErrorMail(false);
    } else {
      setErrorMail(true);
    }
  }

  return (
    <Fragment>
      <Header />
      <Layout className="container">
        <Content className="loginFormHolder">
          <LoginHeader />
          {!signup ? (
            <Fragment>
              <Form 
                className="login-form" 
                onFinish={correoClave}
                initialValues={{ remember: true }}
              >
                {error ? <Form.Item><Errores mensaje={error} /></Form.Item> : null}
                <Form.Item
                  label="Usuario"
                  name="usuario" 
                  rules={[{ required: true }]}
                  style={{position:'relative',padding:'0 0 25px'}}
                >
                  <Input 
                    onChange={()=>{
                      setErrorMail(false);
                    }}
                    onMouseOut={(e)=>{
                      validarUsuario(e.target.value);
                    }}
                  />
                </Form.Item>
                {errorMail === true ? (
                    <div className="errorMail" style={{margin: '0 0 1em'}}>
                     Por favor introduce una dirección de email válida
                    </div>
                  ): null}                
                <Form.Item
                  label="Contraseña"
                  name="clave"
                  rules={[{ required: true, message: 'Por favor ingresa tu contraseña' }]}
                  style={{position:'relative'}}
                >
                  <Input.Password/>
                </Form.Item>

                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ marginRight: 10 }}
                >
                  Iniciar Sesión
                </Button>

                <div className="socialButtons" style={{
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  width: '100%',
                  margin: '25px 0 0' 
                }}>
                  <Button
                    htmlType="button"
                    className="social-btn"
                    style={{ 
                      background: 'transparent',
                      border: '2px solid #F1F1F5',
                      borderRadius: '10px',
                      width: '45%',
                      height: '45px',
                      outline: 'none',
                    }}
                    onClick={() => socialLogin(googleAuthProvider)}
                  >
                    Google
                  </Button>
                  <Button
                    htmlType="button"
                    className="social-btn"
                    style={{ 
                      background: 'transparent',
                      border: '2px solid #F1F1F5',
                      borderRadius: '10px',
                      width: '45%',
                      height: '45px',
                      outline: 'none',
                    }}
                    onClick={() => socialLogin(facebookAuthProvider)}
                  >
                    Facebook
                </Button>
                </div>

                <div className="password-actions">
                  <Checkbox> Recuerdame </Checkbox>
                  <Link to={`/recuperar-password`} className="forgotLink">  
                    Olvidé mi contraseña 
                  </Link>
                </div>
               
                
              </Form>
              <p className="no-count-paraf">
                <a href="#"
                  className="newAccountBtn"
                  onClick={(e) => {
                    e.preventDefault();
                    setsignup(true);
                  }}
                  type="link"
                >
                  No tienes cuenta? Registrate
                </a>
              </p>
            </Fragment>
            ) : (
              <Signup setsignup={setsignup} />
            )}
        </Content>
      </Layout>
    </Fragment>
  );
};

export default withRouter(Login);