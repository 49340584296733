import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

const GlaucomaSectionFour = ({submitAnswer}) => {
    return (
        <Fragment>
            <div className="glaucomaSection">
                <div className="glaucoma-question">
                    <h1> ¿Padeces diabetes mellitus </h1>
                    <div className="glaucoma-buttons">
                        <button
                          onClick={()=>{
                            submitAnswer(true);
                          }}
                        > Si </button>
                        <button
                          onClick={()=>{
                           submitAnswer(false);
                          }}
                        > 
                          No
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

GlaucomaSectionFour.propTypes = {};
export default GlaucomaSectionFour;