import React, {useState,useEffect,useContext} from 'react';
import generic_user from '../images/generic_user.png';
import API_CONSTANTS from '../utils/apiConstamts';
import { Auth } from "../context/AuthContext";
var moment = require('moment');
const axios = require('axios');


const UserInfo = ({infoPaciente}) => {
    //const [infoPaciente, setInfoPaciente] = useState({});
    //const user_ident = localStorage.getItem('idFirebase');
    /* const getInfoUsuario = async () => {
        const userIdent = localStorage.getItem('idFirebase');
        console.log('user_ident',userIdent);
        try {
            const resp = await axios.post(`${API_CONSTANTS.services}?action=login_info&id_Firebase=${user_ident}`);
            console.log('info de paciente',resp.data);
            setInfoPaciente(resp !== undefined && resp !==null && resp.data !== undefined && resp.data !== null && resp.data.pacientes !== null && resp.data.pacientes !== undefined ? resp.data.pacientes[0] : {});
        } catch (err) {
            // Handle Error Here
            console.error('error al obtener la info del usuario',err);
        }
    } */

    /* useEffect(() => {
      getInfoUsuario(); 
    }, []); */
    console.log('info Paciente',infoPaciente);
    return (
        <article className="userInfo">
            <header className="userInfo-header">
                <figure className="user-photo">
                    <img src={generic_user} alt="photo"/>
                </figure>

               
                <h1 className="userName"> 
                   {infoPaciente !== undefined &&
                    infoPaciente !== null && 
                    infoPaciente.nombre !== undefined&& 
                    infoPaciente.numbre !== null ?
                     infoPaciente.nombre : null
                   } 
                </h1>
                   
                {infoPaciente !== undefined &&
                 infoPaciente !== null && 
                 infoPaciente.tipo !== undefined&& 
                 infoPaciente.tipo !== null ?
                  (<span className="userRole"> {infoPaciente.tipo} </span>
                  ) :null
                }
            </header>

            <div className="userInfo-body">
                <label> Número de Expediente </label>

                {infoPaciente !== undefined &&
                 infoPaciente!==null && 
                 infoPaciente.expediente !== undefined && 
                 infoPaciente.expediente !== null ?
                  (<p> {infoPaciente.expediente} </p>
                  ) :null
                }

                <label> Email </label>
                
                {infoPaciente !== undefined &&
                 infoPaciente !== null && 
                 infoPaciente.correo !== undefined && 
                 infoPaciente.correo !== null ?
                  (<p> {infoPaciente.correo} </p>
                  ) :null
                }

                <label> Teléfono </label>
                
                {infoPaciente !== undefined && infoPaciente !== null && infoPaciente.telefono !==undefined &&
                infoPaciente.telefono !== null ?
                  (<p> {infoPaciente.telefono} </p>
                  ) :null
                }
                
                {/* <label> Clínica </label>

                <p> Acoxpa </p> */}
            </div>
        </article>
    );
};


export default UserInfo;