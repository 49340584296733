import React from 'react';
import CuadroRetinaUno from '../../images/cuadro_retina_uno.png';
import ImagenJorgeAbajo from '../../images/imagen_jorge_abajo.png';

const RetinaSectionThree = ({handleNextSection}) => {
    return (
      <div className="retinaSection">
        <header className="retinaHeader">
          Prueba de función de la retina
        </header>
        <div className="retina-inner-grid">
           <div className="retina-left">
             <img src={CuadroRetinaUno} alt="cuadro_retina_uno"/> 
             <div className="point"></div>
           </div>
           <div className="retina-right">
             <h3>
               Observando el punto negro
             </h3>
             <p>
              ¿Sigues viendo la rejilla completa? 
             </p>
             <div retina-buttons>
                <button 
                 className="retina-button-item"
                 onClick={()=>{
                  handleNextSection(true); 
                 }}
                >
                   Si
                </button>
                <button 
                 className="retina-button-item"
                 onClick={()=>{
                   handleNextSection(false); 
                 }}
                >
                   No
                </button>
             </div>
           </div>
        </div>
      </div>
    );
};

export default RetinaSectionThree;


