import React from 'react';
import CuadroRetinaUno from '../../images/cuadro_retina_uno.png';
import ImagenJorgeAbajo from '../../images/imagen_jorge_abajo.png';

const RetinaSectionOne = ({handleNextSection}) => {
    return (
      <div className="retinaSection">
        <header className="retinaHeader">
          Prueba de función de la retina
        </header>
        <div className="retina-inner-grid">
           <div className="retina-left">
             <img src={CuadroRetinaUno} alt="cuadro_retina_uno"/> 
             <div className="point"></div>
           </div>
           <div className="retina-right">
             <p>
               Mira la imagen a una distancia de 30 cm. Si usas lentes de lectura, debes usarlos para realizar la prueba
             </p>
             <p>
               Observa el punto negro con un solo ojo a la vez, tapando el otro con tu mano y da clic para continuar
             </p>
             <button 
              className="retina-next-button"
              onClick={()=>{
                handleNextSection();
              }}
             >
                Continuar
             </button>
             <div className="figure-retina">
                <img src={ImagenJorgeAbajo} alt="imagen jorge"/>
             </div>
           </div>
        </div>
      </div>
    );
};

export default RetinaSectionOne;


