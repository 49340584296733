import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import app from "../firebaseConfig";

const MobileMenu = ({ openMenu }) => {

    return (
        <Fragment>
            <section className={openMenu === true ? 'pushMenu open' : 'pushMenu'}>
                <header className="sidebar-header">
                    <figure className="logo">
                        <img src="https://salauno.com.mx/wp-content/themes/understrap-child-master/img/monito-creemos.png" alt="logo" />
                    </figure>
                    <h1 className="sidebar-title">
                        Bienvenido
                    </h1>
                </header>
                <nav className="mobileMenu-nav">
                    <ul>
                        <li>
                            <span className="linkIcon">
                                <svg id="ic_Dashboard" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Shape" d="M16,20H15a4,4,0,0,1-4-4V13a4,4,0,0,1,4-4h1a4,4,0,0,1,4,4v3A4,4,0,0,1,16,20Zm-1-9a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h1a2,2,0,0,0,2-2V13a2,2,0,0,0-2-2ZM5,20H4a4,4,0,0,1-4-4V14a4,4,0,0,1,4-4H5a4,4,0,0,1,4,4v2A4,4,0,0,1,5,20ZM4,12a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2H5a2,2,0,0,0,2-2V14a2,2,0,0,0-2-2ZM3.5,8A3.5,3.5,0,0,1,0,4.5v-1a3.5,3.5,0,1,1,7,0v1A3.5,3.5,0,0,1,3.5,8Zm0-6A1.5,1.5,0,0,0,2,3.5v1a1.5,1.5,0,0,0,3,0v-1A1.5,1.5,0,0,0,3.5,2Zm13,5h-4a3.5,3.5,0,1,1,0-7h4a3.5,3.5,0,0,1,0,7Zm-4-5a1.5,1.5,0,1,0,0,3h4a1.5,1.5,0,0,0,0-3Z" transform="translate(2 2)" fill="#2e748c" />
                                </svg>
                            </span>
                            <Link to={'/'}> Inicio </Link>
                        </li>
                        <li>
                            <span className="linkIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28.925" height="22.53" viewBox="0 0 28.925 22.53">
                                    <g id="Grupo_4413" data-name="Grupo 4413" transform="translate(-1403.47 -302.652)">
                                        <path id="Trazado_26740" data-name="Trazado 26740" d="M1422.25,306.989h6.116a3.038,3.038,0,0,1,3.029,3.03v11.134a3.038,3.038,0,0,1-3.029,3.029H1407.5a3.038,3.038,0,0,1-3.029-3.029V310.019a3.038,3.038,0,0,1,3.029-3.03h6.058" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                        <path id="Trazado_26741" data-name="Trazado 26741" d="M1422.25,306.989" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                        <path id="Trazado_26742" data-name="Trazado 26742" d="M1412.612,306.989" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                        <g id="Grupo_4411" data-name="Grupo 4411">
                                            <path id="Trazado_26743" data-name="Trazado 26743" d="M1413.051,311.962a1.429,1.429,0,1,1-1.429-1.43A1.428,1.428,0,0,1,1413.051,311.962Z" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                        </g>
                                        <g id="Grupo_4412" data-name="Grupo 4412">
                                            <path id="Trazado_26744" data-name="Trazado 26744" d="M1414.379,320.9v-3.005a2.757,2.757,0,0,0-5.514,0V320.9" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                        </g>
                                        <line id="Línea_142" data-name="Línea 142" x2="8.179" transform="translate(1418.86 313.703)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                        <line id="Línea_143" data-name="Línea 143" x2="8.179" transform="translate(1418.86 317.877)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                        <path id="Trazado_26745" data-name="Trazado 26745" d="M1417.933,309.121h0a1.687,1.687,0,0,1-1.683-1.683v-2.1a1.687,1.687,0,0,1,1.683-1.682h0a1.688,1.688,0,0,1,1.683,1.682v2.1A1.688,1.688,0,0,1,1417.933,309.121Z" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    </g>
                                </svg>

                            </span>
                            <Link to={'/mis-datos'}> Mis Datos </Link>
                        </li>

                        <li>
                            <span className="linkIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25.644" height="24.483" viewBox="0 0 25.644 24.483">
                                    <g id="Grupo_4417" data-name="Grupo 4417" transform="translate(-1008.571 -292.13)">
                                        <g id="Grupo_4414" data-name="Grupo 4414" transform="translate(1009.571 293.13)">
                                            <path id="Trazado_26746" data-name="Trazado 26746" d="M1014.262,315.613a4.721,4.721,0,0,1-4.691-4.723V300.206a4.721,4.721,0,0,1,4.691-4.723h14.262a4.721,4.721,0,0,1,4.69,4.723V310.89a4.721,4.721,0,0,1-4.69,4.723h-8.6" transform="translate(-1009.571 -293.13)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                            <line id="Línea_144" data-name="Línea 144" y2="4.783" transform="translate(7.301)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                            <line id="Línea_145" data-name="Línea 145" y2="4.783" transform="translate(16.095)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                            <line id="Línea_146" data-name="Línea 146" x2="23.644" transform="translate(0 8.176)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                        </g>
                                        <g id="Grupo_4416" data-name="Grupo 4416" transform="translate(1019.543 304.67)">
                                            <g id="Grupo_4415" data-name="Grupo 4415">
                                                <path id="Trazado_26747" data-name="Trazado 26747" d="M1020.627,305.475h-.021l-.655.448a.773.773,0,0,1-.436.135.6.6,0,0,1-.6-.614.747.747,0,0,1,.364-.614l1.113-.769a1.533,1.533,0,0,1,.884-.322.8.8,0,0,1,.811.883v5.543a.729.729,0,1,1-1.456,0Z" transform="translate(-1018.911 -303.739)" fill="#92929d" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <Link to={'/citas'}> Mis Citas </Link>
                        </li>
                        <li>
                            <span className="linkIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26.142" height="18.882" viewBox="0 0 26.142 18.882">
                                    <path id="Trazado_26749" data-name="Trazado 26749" d="M1107.118,321.3v2.345a1.409,1.409,0,0,0,1.479,1.327l21.261,0a1.336,1.336,0,0,0,1.4-1.259V311.081a1.354,1.354,0,0,0-1.421-1.276h-12.312a2.387,2.387,0,0,0-2.416-1.711h-6.572a1.356,1.356,0,0,0-1.422,1.277V316" transform="translate(-1106.118 -307.094)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </svg>
                            </span>
                            <Link to={'/tratamientos'}>  Mis Tratamientos </Link>
                        </li>
                        <li>
                            <span className="linkIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33.104" height="18.52" viewBox="0 0 33.104 18.52">
                                    <g id="Grupo_4419" data-name="Grupo 4419" transform="translate(-395.383 -645.524)">
                                        <path id="Trazado_26750" data-name="Trazado 26750" d="M403.357,654.783a8.78,8.78,0,1,0,8.763-8.259" transform="translate(0)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                        <path id="Trazado_26751" data-name="Trazado 26751" d="M407.969,654.2a3.064,3.064,0,1,0,3.064-3.063" transform="translate(1.088 0.585)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                        <path id="Trazado_26752" data-name="Trazado 26752" d="M412.12,663.044c10.272,0,15.366-8.261,15.366-8.261h0s-5.095-8.259-15.366-8.259-15.364,8.41-15.364,8.41" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    </g>
                                </svg>

                            </span>
                            <Link to={'/control-visual'}>  Control Visual </Link>
                        </li>
                    </ul>
                </nav>
                <button
                    onClick={
                        () => app.auth().signOut()
                    }
                    key="logout" type="primary"
                    className="mobile-sesion-close"
                >
                    Cerrar Sesión
                </button>
            </section>
        </Fragment>
    );
};


export default MobileMenu;