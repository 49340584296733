import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import API_CONSTANTS from '../utils/apiConstamts';
const axios = require('axios');

const VisualInfo = () => {
    const [adicion, setAdicion] = useState(null);
    const [capacidadVisualOjoDerecho, setCapacidadVisualOjoDerecho] = useState(null);
    const [capacidadVisualOjoIzquierdo, setCapacidadVisualOjoIzquierdo] = useState(null);
    const [cilindroOjoDerechoRefracion, setCilindroOjoDerechoRefraccion] = useState(null);
    const [cilindroOjoIzquierdoRefraccion, setCilindroOjoIzquierdoRefraccion] = useState(null);
    const [ejeOjoDerechoRefraccion, setEjeOjoDerechoRefraccion] = useState(null);
    const [ejeOjoIzquierdoRefraccion, setEjeOjoIzquierdoRefraccion] = useState(null);
    const [esferaOjoDerecho, setEsferaOjoDerecho] = useState(null);
    const [esferaOjoIzquierdo, setEsferaOjoIzquierdo] = useState(null);
    const [prismaOjoDerecho, setPrismaOjoDerecho] = useState(null);
    const [prismaOjoIzquierdo, setPrismaOjoIzquierdo] = useState(null); 
    const graduationsParams = {
      "action":"login_graduaciones",
      "idConsulta":"a1ze0000001hVMTAA2"
    }

    const getGraduationData = async () => {
      try {
        const resp = await axios.post(`${API_CONSTANTS.services}?action=${graduationsParams['action']}&idConsulta=${graduationsParams['idConsulta']}`);
        const result = resp.data.graduacion;
        setAdicion(
         result && result !== null && result.adicion !== null ? result.adicion : '' 
        );
          
        setCapacidadVisualOjoIzquierdo(
          result && result !== null && result.capacidadVisualOjoIzquierdo !== null ? 
          result && result.capacidadVisualOjoIzquierdo : '' 
        );

        setCapacidadVisualOjoDerecho(
          result && result !== null && result.capacidadVisualOjoDerecho !== null ? 
          result && result.capacidadVisualOjoDerecho : ''  
        );

        setCilindroOjoIzquierdoRefraccion(
          result && result !== null && result.cilindroOjoIzquierdoRefraccion !== null ? 
          result && result.cilindroOjoIzquierdoRefraccion : ''  
        );

        setCilindroOjoDerechoRefraccion(
          result && result !== null && result.cilindroOjoDerechoRefracion !== null ? 
          result && result.cilindroOjoDerechoRefracion : ''  
        );

        setEjeOjoDerechoRefraccion(
          result && result !== null && result.ejeOjoDerechoRefraccion !== null ? 
          result && result.ejeOjoDerechoRefraccion : ''  
        );   
          
        setEjeOjoIzquierdoRefraccion(
          result && result !== null && result.ejeOjoIzquierdoRefraccion !== null ? 
          result && result.ejeOjoIzquierdoRefraccion : ''  
        );

        setEsferaOjoDerecho(
          result && result !== null && result.esferaOjoDerecho !== null ? 
          result && result.esferaOjoDerecho : ''   
        );

        setEsferaOjoIzquierdo(
          result && result !== null && result.esferaOjoIzquierdo!== null ? 
          result && result.esferaOjoIzquierdo: ''   
        );

        setPrismaOjoIzquierdo(
          result && result !== null && result.prismaOjoIzquierdo!== null ? 
          result && result.prismaOjoIzquierdo: ''    
        );

        setPrismaOjoDerecho(
          result && result !== null && result.prismaOjoDerecho !== null ? 
          result && result.prismaOjoDerecho: ''    
        );

      } catch (err) {
        // Handle Error Here
        console.error('error en peticion axios',err);
      }
    } 

    useEffect(() => {
      getGraduationData();  
    }, []);

    return (
        <Fragment>
            <article className="visual-article">
                <header className="visual-header">
                    Capacidad Visual
                    <span className="visual-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="33.104" height="18.52" viewBox="0 0 33.104 18.52">
                            <g id="Grupo_4423" dataname="Grupo 4423" transform="translate(-395.383 -645.524)">
                                <path id="Trazado_26750" dataname="Trazado 26750" d="M403.357,654.783a8.78,8.78,0,1,0,8.763-8.259" transform="translate(0)" fill="none" stroke="#2c7087" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <path id="Trazado_26751" dataname="Trazado 26751" d="M407.969,654.2a3.064,3.064,0,1,0,3.064-3.063" transform="translate(1.088 0.585)" fill="none" stroke="#2c7087" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <path id="Trazado_26752" dataname="Trazado 26752" d="M412.12,663.044c10.272,0,15.366-8.261,15.366-8.261h0s-5.095-8.259-15.366-8.259-15.364,8.41-15.364,8.41" fill="none" stroke="#2c7087" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            </g>
                        </svg>
                    </span>
                </header>

                <div className="eye-table-header">
                    <section className="eye-table-side">
                        <span className="eye-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.787" height="23.349" viewBox="0 0 30.787 23.349">
                                <g id="Grupo_4392" dataname="Grupo 4392" transform="translate(-399.186 -801.7)">
                                    <g id="Grupo_4391" dataname="Grupo 4391" transform="translate(403.57 808.036)">
                                        <path id="Trazado_26725" dataname="Trazado 26725" d="M437.145,832.4a5.767,5.767,0,1,0,5.767-5.766" transform="translate(-432.186 -826.637)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26726" dataname="Trazado 26726" d="M452.149,843.939a2.3,2.3,0,1,0,2.3-2.3" transform="translate(-443.725 -838.174)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26727" dataname="Trazado 26727" d="M426.4,838.169a13.21,13.21,0,0,0,10.727-5.766h0a12.8,12.8,0,0,0-21.453.1" transform="translate(-415.67 -826.637)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </g>
                                    <path id="Trazado_26728" dataname="Trazado 26728" d="M399.936,867.129v3.078a3.834,3.834,0,0,0,3.834,3.834h2.863" transform="translate(0 -49.742)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Trazado_26729" dataname="Trazado 26729" d="M504.453,867.129v3.078a3.834,3.834,0,0,1-3.834,3.834h-2.863" transform="translate(-75.23 -49.742)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Trazado_26730" dataname="Trazado 26730" d="M399.936,809.362v-3.078a3.834,3.834,0,0,1,3.834-3.834h2.863" transform="translate(0 0)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Trazado_26731" dataname="Trazado 26731" d="M504.453,809.362v-3.078a3.834,3.834,0,0,0-3.834-3.834h-2.863" transform="translate(-75.23 0)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </g>
                            </svg>
                        </span>
                        <span className="eye-title"> Ojo Izquierdo </span>
                    </section>

                    <section className="eye-table-side">
                        <span className="eye-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.787" height="23.349" viewBox="0 0 30.787 23.349">
                                <g id="Grupo_4392" dataname="Grupo 4392" transform="translate(-399.186 -801.7)">
                                    <g id="Grupo_4391" dataname="Grupo 4391" transform="translate(403.57 808.036)">
                                        <path id="Trazado_26725" dataname="Trazado 26725" d="M437.145,832.4a5.767,5.767,0,1,0,5.767-5.766" transform="translate(-432.186 -826.637)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26726" dataname="Trazado 26726" d="M452.149,843.939a2.3,2.3,0,1,0,2.3-2.3" transform="translate(-443.725 -838.174)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Trazado_26727" dataname="Trazado 26727" d="M426.4,838.169a13.21,13.21,0,0,0,10.727-5.766h0a12.8,12.8,0,0,0-21.453.1" transform="translate(-415.67 -826.637)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </g>
                                    <path id="Trazado_26728" dataname="Trazado 26728" d="M399.936,867.129v3.078a3.834,3.834,0,0,0,3.834,3.834h2.863" transform="translate(0 -49.742)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Trazado_26729" dataname="Trazado 26729" d="M504.453,867.129v3.078a3.834,3.834,0,0,1-3.834,3.834h-2.863" transform="translate(-75.23 -49.742)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Trazado_26730" dataname="Trazado 26730" d="M399.936,809.362v-3.078a3.834,3.834,0,0,1,3.834-3.834h2.863" transform="translate(0 0)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    <path id="Trazado_26731" dataname="Trazado 26731" d="M504.453,809.362v-3.078a3.834,3.834,0,0,0-3.834-3.834h-2.863" transform="translate(-75.23 0)" fill="none" stroke="#00748c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                </g>
                            </svg>
                        </span>
                        <span className="eye-title"> Ojo Derecho </span>
                    </section>
                </div>

                <div className="eye-table-body">
                    <section className="eye-table-column">
                        <label htmlFor="">
                          Potencial de Catarata
                        </label>
                        <p> {adicion} </p>
                        <label htmlFor="">
                          NO/OI
                        </label>
                        <p> {capacidadVisualOjoIzquierdo} </p>
                        <label htmlFor="">
                          NC_OI
                        </label>
                        <p> {cilindroOjoIzquierdoRefraccion} </p>
                        <label htmlFor="">
                          C_OI
                        </label>
                        <p> {ejeOjoIzquierdoRefraccion} </p>
                        <label htmlFor="">
                          SC_OI
                        </label>
                        <p> {esferaOjoIzquierdo} </p>

                        <label htmlFor="">
                          P OI
                        </label>
                        <p> {prismaOjoIzquierdo} </p>
                    </section>

                    <section className="eye-table-column">
                        <label htmlFor="">
                        Potencial de Catarata
                        </label>
                        <p> {adicion} </p>
                        <label htmlFor="">
                        NO/OD
                        </label>
                        <p> {capacidadVisualOjoDerecho} </p>
                        <label htmlFor="">
                        NC_OD
                        </label>
                        <p> {cilindroOjoDerechoRefracion} </p>
                        <label htmlFor="">
                        C_OD
                        </label>
                        <p> {ejeOjoDerechoRefraccion} </p>
                        <label htmlFor="">
                          SC_OI
                        </label>
                        <p> {esferaOjoDerecho} </p>

                        <label htmlFor="">
                          P OD
                        </label>
                        <p> {prismaOjoDerecho} </p>
                    </section>
                </div>
            </article>
        </Fragment>
    );
};

export default VisualInfo;