import React, { Fragment } from 'react';
import { Auth } from "../context/AuthContext";
import firebase from "firebase/app";
import { NavLink } from 'react-router-dom';
import { Button } from "antd";
import app from "../firebaseConfig";
import ojo from "../images/ojo.png";
//const userIdent = JSON.parse(localStorage.getItem('id'));

const AdminSidebar = ({displayName}) => {
  return (
    <Fragment>
      <section className="sidebar">
        <header className="sidebar-header">
          <figure className="logo">
            <svg xmlns="http://www.w3.org/2000/svg" width="113.207" height="85.335" viewBox="0 0 113.207 85.335">
              <g id="Grupo_4410" data-name="Grupo 4410" transform="translate(-1020.599 -478.158)">
                <g id="Grupo_4394" data-name="Grupo 4394" transform="translate(1050.231 550.847)" opacity="0.191">
                  <path id="Trazado_26732" data-name="Trazado 26732" d="M1133.461,600.479l-1.777-.137.169-.029c.778-.133.578-.305-.433-.388a5.234,5.234,0,0,0,1.34-.171c1.665-.632-.027-1.213-3.1-1.671.472-.076.948-.148,1.415-.228,5.838-1,8.734-1.909,9.1-2.717,1.36-.026,2.782-.081,4.079-.138,1.491-.065,3.31-.132,4.485-.268,2.577-.3.849-.74-2.656-.68-1.527.029-3.085.083-4.574.147-.607.026-1.273.054-1.923.085a10.746,10.746,0,0,0-2.3-.693c1.439-.108,2.832-.246,4.085-.38,1.491-.158,3.38-.338,4.227-.552,1.867-.47-1.892-.837-5.758-.562-1.674.122-3.3.274-4.792.432-.881.093-1.892.194-2.746.305-1.431-.164-3.053-.317-4.839-.461,1.029-.169,1.946-.357,2.762-.534a9.98,9.98,0,0,0,2.762-.779c.468-.572-4.791-.746-8.073-.27-1.414.211-2.668.449-3.766.685-.572.123-1.232.258-1.767.4a187.512,187.512,0,0,0-39.067,2.083c-.309.053-.563.1-.856.157-3.492-.35-4.237-.976-2.762-1.571.8-.321-3.169-.461-3.974-.136-2.066.833-.816,1.7,4.345,2.167-9,1.873-7.24,3.394,2.951,4.48l-6.973,1.232a5.372,5.372,0,0,1-1.478.06l-1.984-.146-1.128-.083c-.907.144-1.837.3-2.054.342.287.049,2.169.322,3.824.444a26.766,26.766,0,0,0,6.937-.259l6.9-1.218c.258.021.49.043.756.063q1.285.1,2.578.18l-6.907,1.22c-2.16.382-1.932.63.771.83,1.619.12,4.47.2,5.113.216.278-.043,1.115-.2,1.886-.353l-1.127-.083-1.973-.148c-.434-.032-.749-.068-.122-.179l7.063-1.248-.146-.007a194.122,194.122,0,0,0,34.867-1.407c1.951.318,3.043.7,1.969,1.108-.091.034-.116.066-.106.1l-.027,0a16.575,16.575,0,0,0-3.34.1l-.168.028-1.777-.136a27.875,27.875,0,0,0-5.606.167l-6.012,1.031c-.2.035-.146.08.121.1l15.531,1.193a4.2,4.2,0,0,0,.846-.025l6.011-1.032C1135.585,600.909,1135.227,600.614,1133.461,600.479Zm-2.744-.211-4.606-.354.169-.029a8.14,8.14,0,0,1,1.648-.049l2.723.209c.519.04.624.127.235.194Z" transform="translate(-1066.402 -590.515)" fill="#115e67" />
                </g>
                <g id="Grupo_4397" data-name="Grupo 4397" transform="translate(1050.439 548.386)">
                  <g id="Grupo_4396" data-name="Grupo 4396" transform="translate(0)">
                    <path id="Trazado_26733" data-name="Trazado 26733" d="M1075.255,587.225l.15,10.09c.047,3.112-1.141,4.459-3.974,4.5a17.778,17.778,0,0,1-4.7-.855c-.03-.346,0-1.624.045-2.841l1.181-.019c.69-.013,1.491-.03,2.074-.039.459-.007.838-.085.825-.989l-.153-10.311a34.289,34.289,0,0,0,4.549.462m11.1,10.6c-.692.007-1.495.014-2.075.023-.455.006-.839-.06-.852-.964l-.151-10.176a35.537,35.537,0,0,1-4.537.5l.15,10.054c.047,3.112,1.273,4.423,4.106,4.381a17.211,17.211,0,0,0,4.666-1.006c.019-.367-.039-1.626-.123-2.826Z" transform="translate(-1066.723 -586.711)" fill="#255e67" />
                  </g>
                </g>
                <g id="Grupo_4398" data-name="Grupo 4398" transform="translate(1020.599 511.908)">
                  <path id="Trazado_26734" data-name="Trazado 26734" d="M1036.43,539.007c-5.66,1.543-10.365-2.321-12.224-7.449-.787-2.172-4.286-1.235-3.49.963,2.526,6.971,9.009,12.066,16.676,9.977a1.811,1.811,0,0,0-.962-3.49Z" transform="translate(-1020.599 -530.326)" fill="#255e67" />
                </g>
                <g id="Grupo_4403" data-name="Grupo 4403" transform="translate(1032.89 478.158)">
                  <g id="Grupo_4400" data-name="Grupo 4400" transform="translate(45.005 42.367)">
                    <path id="Trazado_26736" data-name="Trazado 26736" d="M1110.911,547.265c4.737-.022,11.2,1.478,12.911,6.479.75,2.191,4.247,1.25,3.49-.963-2.283-6.671-9.89-9.166-16.4-9.135-2.328.011-2.333,3.631,0,3.619Z" transform="translate(-1109.163 -543.646)" fill="#255e67" />
                  </g>
                  <g id="Grupo_4402" data-name="Grupo 4402" transform="translate(0)">
                    <path id="Trazado_26737" data-name="Trazado 26737" d="M1127.721,497.717a1.885,1.885,0,0,1,.9.238,1.69,1.69,0,0,1,.686.679,1.852,1.852,0,0,1,0,1.835,1.7,1.7,0,0,1-.68.681,1.846,1.846,0,0,1-1.83,0,1.717,1.717,0,0,1-.68-.681,1.844,1.844,0,0,1,0-1.835,1.7,1.7,0,0,1,.688-.679,1.88,1.88,0,0,1,.9-.238m0,.305a1.56,1.56,0,0,0-.753.2,1.415,1.415,0,0,0-.574.567,1.533,1.533,0,0,0,0,1.529,1.437,1.437,0,0,0,.569.567,1.527,1.527,0,0,0,1.524,0,1.432,1.432,0,0,0,.568-.567,1.544,1.544,0,0,0,0-1.529,1.4,1.4,0,0,0-.573-.567,1.568,1.568,0,0,0-.753-.2m-.805,2.55v-1.978h.679a1.651,1.651,0,0,1,.5.055.478.478,0,0,1,.248.191.508.508,0,0,1,.093.29.525.525,0,0,1-.155.377.613.613,0,0,1-.41.18.565.565,0,0,1,.168.1,2.239,2.239,0,0,1,.292.392l.242.388h-.391l-.175-.312a1.572,1.572,0,0,0-.334-.461.411.411,0,0,0-.256-.067h-.187v.841Zm.319-1.114h.388a.628.628,0,0,0,.378-.083.284.284,0,0,0,.052-.376.289.289,0,0,0-.136-.1.982.982,0,0,0-.32-.034h-.362Z" transform="translate(-1070.061 -485.063)" fill="#ffcf00" />
                    <g id="Grupo_4401" data-name="Grupo 4401">
                      <path id="Trazado_26738" data-name="Trazado 26738" d="M1088.484,500.181a17.908,17.908,0,0,0,2.4-2.566,9.544,9.544,0,0,0,2.144-3.477c.615-2.821-2.538-4.58-4.847-2.706a20.11,20.11,0,0,0-2.724,2.832c-.331.416-.7.861-1.042,1.326a21.7,21.7,0,0,0-4.644-2.934,23.587,23.587,0,0,0,1.372-3.941c.416-1.551,1.029-3.4.736-4.99-.64-3.488-4.953-3.926-6.615-.679a25.5,25.5,0,0,0-1.668,4.545,25.781,25.781,0,0,0-.707,2.935,36.7,36.7,0,0,0-5.511-.508,31.487,31.487,0,0,0-.256-4.243c-.208-1.826-.343-4.062-1.3-5.632-2.1-3.458-6.693-2.15-7.067,1.987a30.4,30.4,0,0,0,.165,5.539c.107.95.2,2.008.4,3.025-12.315,2.491-19.719,11.86-19.719,28.9,0,20.025,10.323,29.487,26.908,29.487s26.912-9.462,26.912-29.487c0-8.244-1.753-14.669-4.933-19.417m-21.976,37.6c-8.844,0-13.515-6.14-13.515-18.181,0-12.163,4.671-18.3,13.515-18.3s13.513,6.141,13.513,18.3c0,12.042-4.666,18.181-13.513,18.181" transform="translate(-1039.598 -478.158)" fill="#ffcf00" />
                      <path id="Trazado_26739" data-name="Trazado 26739" d="M1075.264,534.47v5.442a2.311,2.311,0,0,1-4.622,0V534.47a2.311,2.311,0,1,1,4.622,0m4.411,0v5.442a2.309,2.309,0,1,0,4.619,0V534.47a2.309,2.309,0,1,0-4.619,0" transform="translate(-1050.558 -497.222)" fill="#255e67" />
                    </g>
                  </g>
                </g>
                <rect id="Rectángulo_2975" data-name="Rectángulo 2975" width="5.923" height="3.693" rx="0.601" transform="translate(1069.037 538.173)" fill="#007488" />
              </g>
            </svg>

          </figure>
          <h1 className="sidebar-title">
            Bienvenido
            <span> {displayName} </span>
          </h1>
        </header>
        <nav className="sidebar-nav">
          <ul>
            <li>
              <NavLink to={`/inicio`} activeClassName="active">
                <span className="linkIcon">
                  <svg id="ic_Dashboard" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path id="Shape" d="M16,20H15a4,4,0,0,1-4-4V13a4,4,0,0,1,4-4h1a4,4,0,0,1,4,4v3A4,4,0,0,1,16,20Zm-1-9a2,2,0,0,0-2,2v3a2,2,0,0,0,2,2h1a2,2,0,0,0,2-2V13a2,2,0,0,0-2-2ZM5,20H4a4,4,0,0,1-4-4V14a4,4,0,0,1,4-4H5a4,4,0,0,1,4,4v2A4,4,0,0,1,5,20ZM4,12a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2H5a2,2,0,0,0,2-2V14a2,2,0,0,0-2-2ZM3.5,8A3.5,3.5,0,0,1,0,4.5v-1a3.5,3.5,0,1,1,7,0v1A3.5,3.5,0,0,1,3.5,8Zm0-6A1.5,1.5,0,0,0,2,3.5v1a1.5,1.5,0,0,0,3,0v-1A1.5,1.5,0,0,0,3.5,2Zm13,5h-4a3.5,3.5,0,1,1,0-7h4a3.5,3.5,0,0,1,0,7Zm-4-5a1.5,1.5,0,1,0,0,3h4a1.5,1.5,0,0,0,0-3Z" transform="translate(2 2)" fill="#2e748c" />
                  </svg>
                </span>
                Inicio
              </NavLink>
            </li>
            <li>

              <NavLink to={`/mis-datos`}>
                <span className="linkIcon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="28.925" height="22.53" viewBox="0 0 28.925 22.53">
                    <g id="Grupo_4413" data-name="Grupo 4413" transform="translate(-1403.47 -302.652)">
                      <path id="Trazado_26740" data-name="Trazado 26740" d="M1422.25,306.989h6.116a3.038,3.038,0,0,1,3.029,3.03v11.134a3.038,3.038,0,0,1-3.029,3.029H1407.5a3.038,3.038,0,0,1-3.029-3.029V310.019a3.038,3.038,0,0,1,3.029-3.03h6.058" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      <path id="Trazado_26741" data-name="Trazado 26741" d="M1422.25,306.989" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      <path id="Trazado_26742" data-name="Trazado 26742" d="M1412.612,306.989" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      <g id="Grupo_4411" data-name="Grupo 4411">
                        <path id="Trazado_26743" data-name="Trazado 26743" d="M1413.051,311.962a1.429,1.429,0,1,1-1.429-1.43A1.428,1.428,0,0,1,1413.051,311.962Z" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      </g>
                      <g id="Grupo_4412" data-name="Grupo 4412">
                        <path id="Trazado_26744" data-name="Trazado 26744" d="M1414.379,320.9v-3.005a2.757,2.757,0,0,0-5.514,0V320.9" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      </g>
                      <line id="Línea_142" data-name="Línea 142" x2="8.179" transform="translate(1418.86 313.703)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      <line id="Línea_143" data-name="Línea 143" x2="8.179" transform="translate(1418.86 317.877)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      <path id="Trazado_26745" data-name="Trazado 26745" d="M1417.933,309.121h0a1.687,1.687,0,0,1-1.683-1.683v-2.1a1.687,1.687,0,0,1,1.683-1.682h0a1.688,1.688,0,0,1,1.683,1.682v2.1A1.688,1.688,0,0,1,1417.933,309.121Z" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    </g>
                  </svg>

                </span>
                Mis Datos
              </NavLink>
            </li>
            <li>
              <NavLink to={`/citas`}>
                <span className="linkIcon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25.644" height="24.483" viewBox="0 0 25.644 24.483">
                    <g id="Grupo_4417" data-name="Grupo 4417" transform="translate(-1008.571 -292.13)">
                      <g id="Grupo_4414" data-name="Grupo 4414" transform="translate(1009.571 293.13)">
                        <path id="Trazado_26746" data-name="Trazado 26746" d="M1014.262,315.613a4.721,4.721,0,0,1-4.691-4.723V300.206a4.721,4.721,0,0,1,4.691-4.723h14.262a4.721,4.721,0,0,1,4.69,4.723V310.89a4.721,4.721,0,0,1-4.69,4.723h-8.6" transform="translate(-1009.571 -293.13)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        <line id="Línea_144" data-name="Línea 144" y2="4.783" transform="translate(7.301)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        <line id="Línea_145" data-name="Línea 145" y2="4.783" transform="translate(16.095)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        <line id="Línea_146" data-name="Línea 146" x2="23.644" transform="translate(0 8.176)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      </g>
                      <g id="Grupo_4416" data-name="Grupo 4416" transform="translate(1019.543 304.67)">
                        <g id="Grupo_4415" data-name="Grupo 4415">
                          <path id="Trazado_26747" data-name="Trazado 26747" d="M1020.627,305.475h-.021l-.655.448a.773.773,0,0,1-.436.135.6.6,0,0,1-.6-.614.747.747,0,0,1,.364-.614l1.113-.769a1.533,1.533,0,0,1,.884-.322.8.8,0,0,1,.811.883v5.543a.729.729,0,1,1-1.456,0Z" transform="translate(-1018.911 -303.739)" fill="#92929d" />
                        </g>
                      </g>
                    </g>
                  </svg>

                </span>
                Mis Citas
              </NavLink>
            </li>
            <li>

              <NavLink to={`/tratamientos`}>
                <span className="linkIcon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26.142" height="18.882" viewBox="0 0 26.142 18.882">
                    <path id="Trazado_26749" data-name="Trazado 26749" d="M1107.118,321.3v2.345a1.409,1.409,0,0,0,1.479,1.327l21.261,0a1.336,1.336,0,0,0,1.4-1.259V311.081a1.354,1.354,0,0,0-1.421-1.276h-12.312a2.387,2.387,0,0,0-2.416-1.711h-6.572a1.356,1.356,0,0,0-1.422,1.277V316" transform="translate(-1106.118 -307.094)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  </svg>
                </span>
                Mis Tratamientos
              </NavLink>
            </li>

            <li>
              <NavLink to={`/control-visual`}>
                <span className="linkIcon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="33.104" height="18.52" viewBox="0 0 33.104 18.52">
                    <g id="Grupo_4419" dataName="Grupo 4419" transform="translate(-395.383 -645.524)">
                      <path id="Trazado_26750" dataName="Trazado 26750" d="M403.357,654.783a8.78,8.78,0,1,0,8.763-8.259" transform="translate(0)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      <path id="Trazado_26751" dataName="Trazado 26751" d="M407.969,654.2a3.064,3.064,0,1,0,3.064-3.063" transform="translate(1.088 0.585)" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                      <path id="Trazado_26752" dataName="Trazado 26752" d="M412.12,663.044c10.272,0,15.366-8.261,15.366-8.261h0s-5.095-8.259-15.366-8.259-15.364,8.41-15.364,8.41" fill="none" stroke="#92929d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    </g>
                  </svg>
                </span>
                Control Visual
              </NavLink>
            </li>

            <li>
              <NavLink to={`/mis-lentes`}>
                <span className="linkIcon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="46.731" height="15.159" viewBox="0 0 46.731 15.159">
                    <g id="Grupo_4419" data-name="Grupo 4419" transform="translate(-388.755 -647.204)">
                      <path id="Trazado_26912" data-name="Trazado 26912" d="M444.4,309.948h-1.883a7.033,7.033,0,0,0-.776-1.979c-.615-1.007-2.84-1.6-6.613-1.765a30.083,30.083,0,0,0-8.274.63c-1.072.3-1.8,1.342-2.108,2.974a13.127,13.127,0,0,0-5.631-.1c-.319-1.575-1.037-2.58-2.087-2.875a30.064,30.064,0,0,0-8.274-.63c-3.773.164-6,.758-6.613,1.765a7.029,7.029,0,0,0-.776,1.979h-1.95a.873.873,0,1,0,0,1.746h1.663c-.3,3.241.464,7.038,2.054,8.328,1.232,1,4.356,1.236,6.109,1.289.295.009.607.014.932.014a18.071,18.071,0,0,0,5.527-.667c2.48-.966,3.671-5.929,3.6-9.2a11.276,11.276,0,0,1,5.282.107c-.043,3.27,1.148,8.136,3.6,9.092a18.065,18.065,0,0,0,5.527.667c.324,0,.637,0,.932-.014,1.752-.053,4.876-.29,6.108-1.289,1.591-1.289,2.359-5.086,2.055-8.328h1.6a.873.873,0,0,0,0-1.746Zm-29.335,9.083c-2.294.894-9.584.648-10.835-.365-.544-.441-1.219-1.994-1.416-4.361a9.848,9.848,0,0,1,.8-5.406c.269-.3,1.976-.828,5.412-.958.5-.019,1-.028,1.489-.028a25.052,25.052,0,0,1,6.038.6c.527.148.915,1.213.988,2.712C417.7,314.487,416.425,318.5,415.061,319.03Zm24.584-.365c-1.25,1.014-8.54,1.26-10.834.365-1.364-.531-2.638-4.543-2.479-7.8.073-1.5.461-2.564.989-2.713a23.509,23.509,0,0,1,5.841-.6c3.347,0,6.6.424,7.085.965C441.737,311.319,441.122,317.468,439.645,318.665Z" transform="translate(-9.783 341.039)" fill="#2e748c" />
                    </g>
                  </svg>
                </span>
                Mis Lentes
              </NavLink>
            </li>
          </ul>
        </nav>
        <Button
          onClick={() => {
            try {
              app.auth().signOut();
              console.log('sesion finalizada');
              localStorage.removeItem('idFirebase');
              localStorage.removeItem('id');
            } catch (error) {
              console.log('error', error);
            }
          }}
          key="logout" type="primary"
        >
          Cerrar Sesión
          </Button>
      </section>
    </Fragment>
  );
};



export default AdminSidebar;