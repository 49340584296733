const reducer = (state,action) => {
    switch(action.type) {
      case 'SET_INFOUSUARIO':
        console.log('payload',action.payload);
        return{
            ...state,
            info_usuario: action.payload 
        }   
      default:
        return state  
    }
  }
  
  export default reducer;