import React, { useContext, useEffect, useState,Fragment } from 'react';
import { Layout} from "antd";
import { Auth } from "../context/AuthContext";
import { withRouter } from "react-router";
import Header from '../components/header';
import Profile from './Profile';
import {Helmet} from "react-helmet";
import { setUserInfo } from '../actions';
import API_CONSTANTS from '../utils/apiConstamts';

const axios = require('axios');
var moment = require('moment');

const Dashboard = ({ history }) => {
    const { Content, Footer } = Layout;
    const { usuario } = useContext(Auth);
    const [nombre, setnombre] = useState(null);
    const [userId, setUserId] = useState(null);
    const [expedientNumber,setExpedientNumber] = useState(null);
    const [profileName, setProfileName] = useState(null);
    const [profileEmail, setProfileEmail] = useState(null);
    const [profilePhone, setProfilePhone] = useState(null);
    const [userInfo, setUserInfo] = useState({});

    const comprobeExpedient = async (usuarioId=null) => {
        console.log('id de usuario',usuarioId);
        try {
          const resp = await axios.post(`${API_CONSTANTS.services}?action=login_info&id_Firebase=${localStorage.getItem("idFirebase")}`);
          const result = resp &&  resp.data && resp.data !== null ? resp.data : []; 
          const expediente =  result && result !== null && result[0] && result[0] !== null && result[0].expediente && result[0].expediente !== null ?  result[0].expediente : null;   
          setExpedientNumber(expediente);
          setProfileName(result && result !== null && result[0] && result[0] !== null && result[0].expediente && result[0].expediente !== null ?  result[0].expediente : null);    
          setUserInfo(
            result !== null && 
            result.pacientes !== null && 
            result.pacientes !== undefined && 
            result.pacientes[0] !== null &&
            result.pacientes[0] !== undefined ?
            result.pacientes[0] : null
          );
        } catch (err) {
            // Handle Error Here
            console.error('error al obtener la info de lentes del usuario',err);
        }
    }
    
    useEffect(() => {  
      if (usuario === null) {
        history.push(`/login`);
      }
      usuario ? usuario.displayName ? setnombre(usuario.displayName) : setnombre(usuario.email) : setnombre(null)
      setUserId(usuario && usuario.uid && usuario !== null && usuario.uid !== null ? usuario.uid : null); 
      setProfileEmail(usuario && usuario.email && usuario !== null && usuario.email !== null ? usuario.email : null);
      setProfilePhone(usuario && usuario.email && usuario !== null && usuario.email !== null ? usuario.email : null);
      comprobeExpedient(usuario && usuario.phoneNumber && usuario !== null && usuario.phoneNumber !== null ? usuario.phoneNumber : null );
    }, [history, usuario]);

    return (
        <Fragment>
            <Helmet>
               <title>Usuarios Sala Uno </title>
               <link rel="apple-touch-icon" href="http://mysite.com/img/apple-touch-icon-57x57.png" />
               <link rel="apple-touch-icon" sizes="72x72" href="http://mysite.com/img/apple-touch-icon-72x72.png" />
            </Helmet>
            
            <Layout>
              <Header 
                userId={userId}
              />
              <Content>
                <Profile
                  userInfo={userInfo}
                />
              </Content>
           </Layout>
        </Fragment>
    );
};



export default withRouter(Dashboard);