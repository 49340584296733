import React, { Fragment, useState } from 'react';
import { Button, Input } from 'antd';

const ExpidentNumber = ({ onOk, onCancel }) => {

    const [expedientNumber, setExpedientNumber] = useState('');
    return (<Fragment>
        
        <Input
          className="create-input"
          value={expedientNumber}
          onChange={({ target }) => setExpedientNumber(target.value)}
        />

        <div>
            <Button onClick={onCancel}>Cancelar</Button>
            <Button type="primary" onClick={() => onOk(expedientNumber)}>Aceptar</Button>
        </div>
    </Fragment>)
}

export default ExpidentNumber;